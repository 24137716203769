import React, { useState } from "react";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { req } from "../../../requests";
import { getAllParams } from "../../../urlParams";
import FooterNew from "./components/Footer";
import TextInput1 from "./components/TextInput";
import Button1 from "./components/Button";

export default function SignIn() {
  const { signedUp } = getAllParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  localStorage.clear();
  // window.location.href = "https://www.kavanhealth.com/log-in"
  // return <></>

  return (
    <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-sign_in-form_container">
          <div
            style={{ alignItems: "flex-start" }}
            className="kwn-fill_out_form-title_container"
          >
            <h1>
              {signedUp
                ? "Espere mientras revisamos su solicitud"
                : "Bienvenido"}
            </h1>
            <h2 style={{ textAlign: "left" }}>
              Inicie sesión para empezar a utilizar Kavan Health
            </h2>
          </div>
          <TextInput1
            type={"text"}
            title={"Business Email"}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            placeholder={"user@company.com"}
          />
        </div>

        <div
          style={{
            color: "red",
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 20,
          }}
        >
          {error}
        </div>
        <div className="kwn-sign_in-Button_container">
          <Button1
            onClick={async () => {
              let success = true;
              setLoading(true);
              const data = await req(
                "POST",
                "/user/login",
                {
                  email: email.toLowerCase().trim(),
                  device: {
                    id: "web",
                    deviceToken: "MockToken",
                  },
                },
                async (message) => {
                  setLoading(false);
                  success = false;
                  setError(message);
                }
              );
              if (!success) return;
              setLoading(false);
              navigate(`/patientVerifyEmail?email=${email}`);
            }}
          >
            {loading ? "Loading..." : "Iniciar sesión"}
          </Button1>
        </div>
        <h3>OR</h3>
        <p
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => navigate("/patientSignIn")}
        >
          Login as a normal user
        </p>
        <div>
          {/* {socialArray.map((item) => { */}
          {/* return ( */}
          {/* <GoogleLogin
            onSuccess={async (credentialResponse) => {
              const jwt = credentialResponse.credential;
              const decoded = jwt_decode(jwt);
              const email = decoded?.email;
              const name = decoded?.name;
              const result = { email, name };

              let success = true;
              setLoading(true);
              const data = await req(
                "POST",
                "/user/socialLogin",
                {
                  email,
                  name,
                  device: {
                    id: "web",
                    deviceToken: "MockToken",
                  },
                },
                (message) => {
                  setLoading(false);
                  success = false;
                  setError(message);
                }
              );
              if (!success) return;
              setLoading(false);
              console.log(data);
              console.log("Tokens =>", data);
              const {
                user,
                userAlreadyExisted,
                token,
                refreshToken,
                accountLink,
              } = data;
              localStorage.setItem("kawan_accessToken", token);
              localStorage.setItem("kawan_refreshToken", refreshToken);
              if (!userAlreadyExisted) return navigate("/choosePofile");
              if (!user.approved) return navigate("/registrationPending");
              if (accountLink) return (window.location.href = accountLink.url);
              navigate("/dashboard/AdminHome");

              console.log(result);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          /> */}
          {/* ); */}
          {/* })} */}
        </div>
      </div>
      {/* <Footer /> */}
      <FooterNew />
    </GoogleOAuthProvider>
  );
}
