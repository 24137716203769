import React, { useState } from "react";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { req } from "../../../requests";
import { getAllParams } from "../../../urlParams";
import FooterNew from "./components/Footer";
import TextInput1 from "./components/TextInput";
import Button1 from "./components/Button";
import CustomPhoneInput from "../../../components/phoneInput";
// import TextInput from "./components/TextInput";
import InputButton from "../../../components/input_button/Button";
import logo from "../../../assets/downarrow.png";
import GoogleButton from "../../../components/GoogleButton/Button";
import GooglButtonIcon from "../../../assets/google.png";
import EmailIcon from "../../../assets/email.png";
import { kavanLogoChatbot } from "../../../assets/index";
import './style.css';


export default function SignIn() {
  const { signedUp } = getAllParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+34");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [buttons, setButton] = useState(false);

  // const [number,setNumber] = useState();
  localStorage.clear();
  // window.location.href = "https://www.kavanhealth.com/log-in"
  // return <></>

  return (
    <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
      {/* <Navbar/> */}
      <div className="kavan__navbar-links_logo" style={{ marginLeft: 40, marginTop: 20 }}>
        <img src={kavanLogoChatbot} alt="Logo" onClick={() => window.location.href = "https://www.kavanhealth.com"} />
      </div>
      <div className="mainDiv">
        <h1 className="headingText">Bienvenido a Kavan Health</h1>
        <h3 className="subHeadingText">Ir a terapia puede ser tu mayor fortaleza.</h3>

        <div style={{ marginTop: 70, alignSelf: 'center' }}>
          {!continueWithEmail && <CustomPhoneInput title="Ingresa tu numero de telefono" codeVisible={true} placeholder="Enter Phone Number" phonenumber={phoneNumber} setphonenumber={setPhoneNumber} selectedcode={selectedCode} setselectedcode={setSelectedCode} />}
          {continueWithEmail && <CustomPhoneInput title="Ingresa tu numero de telefono" placeholder="Enter Email" phonenumber={email} setphonenumber={setEmail} selectedcode={selectedCode} setselectedcode={setSelectedCode} />}

        </div>
        <div className="singin-buttons-container" style={{ display: 'flex', flexDirection: 'row', marginTop: 40, gap: 30 }}>
          <InputButton backgroundColor="#ffffff" color="#04494B" borderRadius={10} onClick={async () => {
            let success = true;
            setLoading(true);
            const data = await req(
              "POST",
              "/user/numberLogin",
              {
                number: `${selectedCode}${phoneNumber.toLowerCase().trim()}`,
                device: {
                  id: "web",
                  deviceToken: "MockToken",
                },
              },
              async (message) => {
                setLoading(false);
                success = false;
                setError(message);
              }
            );
            if (!success) return;
            setLoading(false);
            console.log("email is ", email);
            console.log(data);


            if (!email.trim()) {
              console.log(`maxxxxx ${encodeURIComponent(selectedCode)}${phoneNumber}`)
              navigate(`/patientVerifyEmail?number=${encodeURIComponent(selectedCode)}${phoneNumber}`);
            }
            else {
              navigate(`/patientVerifyEmail?email=${email}`);
            }
          }}>
            Whatsapp
          </InputButton>
          <InputButton backgroundColor="#04494B" borderRadius={10} onClick={async () => {
            let success = true;
            setLoading(true);
            const data = await req(
              "POST",
              "/user/numberLogin",
              {
                number: selectedCode + phoneNumber.toLowerCase().trim(),
                device: {
                  id: "web",
                  deviceToken: "MockToken",
                },
              },
              async (message) => {
                setLoading(false);
                success = false;
                setError(message);
              }
            );
            console.log("email is ", email);
            console.log(data?.user?.preferences);
            localStorage.setItem('kawan_accessToken', data?.user?.accessToken)
            let preference = false;
            if (data?.user?.preferences) {
              preference = true;

            }

            if (!success) return;
            setLoading(false);
            if (email === '') {
              if (preference) {
                navigate(`/patientVerifyEmail?number=${encodeURIComponent(selectedCode)}${phoneNumber}&preferences=${true}`);
              }
              else {
                navigate(`/patientVerifyEmail?number=${encodeURIComponent(selectedCode)}${phoneNumber}`);
              }

            }
            else {
              navigate(`/patientVerifyEmail?email=${email}`);
            }

          }}>SMS</InputButton>
        </div>
        <div style={{ marginTop: 40, cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }} onClick={() => setButton(!buttons)}>
          <h4 style={{ fontFamily: 'Satoshi, sans-serif', color: '#120D30', fontSize: 15 }}>Otros métodos </h4>
          <img src={logo} alt="Logo" style={{ height: 10, width: 15, transform: buttons ? 'rotate(180deg)' : 'none', }} />
        </div>
        {buttons &&
          <>
            <div style={{ marginTop: 30, }}>
              <GoogleButton borderRadius={20} color={true} backgroundColor="#ffffff" GooglButtonIcon={GooglButtonIcon}>Continuar con Google </GoogleButton>
            </div>
            <div style={{ marginTop: 30, }}>
              <GoogleButton borderRadius={20} color={true} backgroundColor="#ffffff" GooglButtonIcon={EmailIcon} onClick={() => { setContinueWithEmail(true); setButton(false) }}>Continuar con Google </GoogleButton>
            </div>
          </>
        }
        <div style={{ marginTop: 70, justifyContent: 'center', alignItems: 'center', }}>
          <h3 className="lastDivText">Al continuar, acepta automáticamente nuestras</h3>
          <h3 className="lastDivText2">{"         " + "Condiciones y Políticas de privacidad"}</h3>

        </div>
      </div>
      {/* <Footer/> */}
    </GoogleOAuthProvider>





  );
}



// <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
//   <Navbar />
//   <div className="kwn-fill_out_form-main_container">
//     <div className="kwn-sign_in-form_container">
//       <div
//         style={{ alignItems: "flex-start" }}
//         className="kwn-fill_out_form-title_container"
//       >
//         <h1>
//           {signedUp
//             ? "Espere mientras revisamos su solicitud"
//             : "Bienvenido"}
//         </h1>
//         <h2 style={{ textAlign: "left" }}>
//           Inicie sesión para empezar a utilizar Kavan Health
//         </h2>
//       </div>
//       <TextInput1
//         type={"text"}
//         title={"Phone Number"}
//         value={number}
//         onChange={(ev) => setNumber(ev.target.value)}
//         placeholder={"+34123123123"}
//       />
//       <div
//         style={{
//           color: "red",
//           display: "flex",
//           justifyContent: "center",
//           fontSize: 20,
//         }}
//       >
//         {error}
//       </div>
//       <div className="kwn-sign_in-Button_container">
//         <Button1
//           onClick={async () => {
//             let success = true;
//             setLoading(true);
//             const data = await req(
//               "POST",
//               "/user/numberLogin",
//               {
//                 number: number.toLowerCase().trim(),
//                 device: {
//                   id: "web",
//                   deviceToken: "MockToken",
//                 },
//               },
//               async (message) => {
//                 setLoading(false);
//                 success = false;
//                 setError(message);
//               }
//             );
//             if (!success) return;
//             setLoading(false);
//             navigate(`/patientVerifyEmail?number=${number}`);
//           }}
//         >
//           {loading ? "Loading..." : "Iniciar sesión"}
//         </Button1>
//       </div>
//       <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//         <h3>OR</h3>
//         <p
//           style={{ textDecoration: "underline", cursor: "pointer" }}
//           onClick={() => navigate("/patientBusinessSignIn")}
//         >
//           Login as a business user
//         </p>
//         <div>
//           {/* {socialArray.map((item) => { */}
//           {/* return ( */}
//           {/* <GoogleLogin
//         onSuccess={async (credentialResponse) => {
//           const jwt = credentialResponse.credential;
//           const decoded = jwt_decode(jwt);
//           const email = decoded?.email;
//           const name = decoded?.name;
//           const result = { email, name };

//           let success = true;
//           setLoading(true);
//           const data = await req(
//             "POST",
//             "/user/socialLogin",
//             {
//               email,
//               name,
//               device: {
//                 id: "web",
//                 deviceToken: "MockToken",
//               },
//             },
//             (message) => {
//               setLoading(false);
//               success = false;
//               setError(message);
//             }
//           );
//           if (!success) return;
//           setLoading(false);
//           console.log(data);
//           console.log("Tokens =>", data);
//           const {
//             user,
//             userAlreadyExisted,
//             token,
//             refreshToken,
//             accountLink,
//           } = data;
//           localStorage.setItem("kawan_accessToken", token);
//           localStorage.setItem("kawan_refreshToken", refreshToken);
//           if (!userAlreadyExisted) return navigate("/choosePofile");
//           if (!user.approved) return navigate("/registrationPending");
//           if (accountLink) return (window.location.href = accountLink.url);
//           navigate("/dashboard/AdminHome");

//           console.log(result);
//         }}
//         onError={() => {
//           console.log("Login Failed");
//         }}
//       /> */}
//           {/* ); */}
//           {/* })} */}
//         </div>
//       </div>
//     </div>
//   </div>
//   {/* <Footer /> */}
//   <FooterNew />
// </GoogleOAuthProvider>