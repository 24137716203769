import React from "react";
import "./button.css";

export default function Button(props) {
  const shouldDisable = props.disabled === undefined ? false : props.disabled;
  return (
    <div
      onClick={shouldDisable ? () => {} : props.onClick}
      className="button_container_patient"
      style={{
        cursor: shouldDisable ? "auto" : props.cursor,
        backgroundColor: props?.backgroundColor
          ? props.backgroundColor
          : props.border
          ? "#fff"
          : "#006039",
        opacity: shouldDisable ? 0.4 : 1,
        marginTop: props.marginTop,
        ...props.style,
      }}
    >
      <p style={{ color: props.border ? "#006039" : "#fff" }}>
        {props.children}
      </p>
    </div>
  );
}
