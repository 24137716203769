import React from "react";
import "./footer.css";
import logo from "./logo.svg";
import appStore from "../../../../assets/appleStore.png";
// import appStore from "../../../../assets/app-store.png";
import playStore from "../../../../assets/playStore.png";
import insta from "../../../../assets/instaFooter.png";
import linkedin from "../../../../assets/linkedIn.png";
import fb from "../../../../assets/fbFooter.png";
function Footer() {
  return (
    <div className="bg">
      <div className="">
        <div className="left">
          <img src={logo} className="logo" alt="" />
        </div>
        <div className="right">
          <div>
            <h1>Explore</h1>
            <a href="/">Home</a>
            <a href="/blog">Blog</a>
            <a href="/nosotros">Us</a>
            <a href="/contactanos">Contact Us</a>
          </div>
          <div>
            <h1>About Kavan Health</h1>
            <a href="">Why Kavan?</a>
            <a href="">Who is Kavan?</a>
            <a href="">Privacy Policy</a>
            <a href="">Terms and Conditions</a>
          </div>
          <div>
            <h1>Contact us</h1>
            <a>admin@kavanhealth.com</a>
            <a>+34 682 105 599</a>
          </div>
          <div>
            <h1>Download our app</h1>
            <div className="download">
              <img className="downloadLogo" src={appStore} alt="" />
              <img className="downloadLogo" src={playStore} alt="" />
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <div className="footer-bottom">
        <h1 className="footerText">All rights reserved - Kavan Health</h1>
        <div className="footer-icons">
          <img src={insta} alt="" />
          <img src={linkedin} alt="" />
          <img src={fb} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
