import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Navbar, TextInput } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import { authReq } from "../../../requests";
import DegreeCaption from "../../../components/degreeCaption/degreeCaption";
import WhiteButton from "../../../components/whiteButton/whiteButton";

// ok

function AddDegree() {
  const navigate = useNavigate();
  const [degrees, setDegrees] = useState(JSON.parse(
    getAllParams()?.degrees ?? '{"degrees": []}'
  )?.degrees);
  console.log("DG =>>", degrees);
  return (
    <>
      <Navbar />
      <div className="kawn-add-phone_number">
        <div className="kwn-fill_out_form-title_container">
          <h1>Add Qualification</h1>
          <h2>Add your highest qualifictaion detail here</h2>
        </div>
        {degrees.map((props, n) => {
          console.log(props);
          return (
            <DegreeCaption
              year={props.year}
              country={props.country}
              degree={props.qualification}
              university={props.university}
              onDelete={() => {console.log(props); setDegrees(degrees.filter(x => JSON.stringify(x) != JSON.stringify(props)))}}
              onEdit={() => navigate(`/addQualification?${setParam({
                current: JSON.stringify(props),
                index: n,
                degrees: JSON.stringify(degrees),
              })}`)}
            />
          );
        })}
        <div style={{ marginTop: "4.4rem" }}>
          <WhiteButton
            text={"ADD EDUCATION"}
            onClick={() =>
              navigate(
                `/addQualification?${setParam({degrees: JSON.stringify(degrees)})}`
              )
            }
          />
        </div>
        <div style={{ marginTop: "15.7rem" }}>
          <Button
            onClick={() =>
              navigate(
                `/dashboard/SettingStack/profile?${setParam({degrees: JSON.stringify(degrees)})}`
              )
            }
          >
            Save
          </Button>
        </div>
        <div className="kawan-add_later-container">
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/dashboard/SettingStack/profile`)}
          >
            Add Later
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddDegree;
