import React from 'react'
import './GoogleButton.css'
import googleIcon from "../../assets/google.png";
import mailIcon from "../../assets/email.png";

export default function GoogleButton(props) {
    const shouldDisable = props.disabled === undefined ? false : props.disabled
    return (
        <div onClick={shouldDisable ? () => { } : props.onClick} className='button' style={{borderRadius:props.borderRadius ? props.borderRadius :0, cursor: shouldDisable ? 'auto' : props.cursor, backgroundColor: props?.backgroundColor ?? props.backgroundColor, opacity: shouldDisable ? 0.4 : 1, marginTop: props.marginTop, ...props.style ,display:'flex',flexDirection:'row',gap:10}}>
            {props.GooglButtonIcon && <img src={props.GooglButtonIcon} style={{height:30,width:30}}/>}
            <p style={{color:props.color ? props.color : '#ffffff',fontFamily: 'Satoshi, sans-serif',color:'#0000008A',fontSize:15 }}>{props.children}</p>
        </div>
    )
}

