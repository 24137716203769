import React, { useEffect, useRef, useState } from 'react';
// import { Loader } from '../../../components';
// import { NETWORK_CALL } from '../../../api/apiCaller';
// import { useDispatch, useSelector } from 'react-redux';
// import routs from '../../../api/routs';
// import { saveChatbotConversations, saveChatBotName, saveChatbotRequested, userData } from '../../../redux/Slices/userDataSlice';
// import Voice from '@react-native-community/voice';
// import { useIsFocused } from '@react-navigation/native';
// import StatusBarComponent from '../../../components/statusBar';
// import appsFlyer from 'react-native-appsflyer';
// import useTranslations from '../../../hooks/useTranslations';
import { authReq, req } from '../../../requests';
import { ScaleLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../../../components';
import { kavanGPT, sendChatbotPro } from '../../../assets';

const Typing = () => (
    <div className="typing">
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  )

const NETWORK_CALL = (method, route, data, setLoading, succ, err) => {
    setLoading(true)
    return authReq(method, route, data, err)
        .then(data => {
            setLoading(false)
            succ({ data })
            return { data }
        })
}

const flowType = 1
const lang = 'es'
const englishQuestion = null

var NAME = null
const ChatBotInner = ({ spanishQuestions }) => {
    const urlParams = new URLSearchParams(window.location.search);

    const t = x => x
    //   const lang = useSelector((state) => state.user?.selectedLanguage);
    //   const englishQuestion = useSelector((state) => state.user?.enChatbot);
    const navigate = useNavigate()
    //   const user = useSelector((state) => state.user?.userData);
    //   const flowType = useSelector(state => state.user?.flowType);

    const flatListRef = useRef(null);
    const activeIndex = useRef(0);
    const milestoneQuestionsAsked = useRef({})
    const milestoneCompleted = useRef(null)

    const adminQuestionsRef = useRef(lang == 'en' ? englishQuestion : spanishQuestions)
    console.log("CHECK ADMIN QUESTIONS", adminQuestionsRef)
    const lengthOfAdminQuestions = adminQuestionsRef.current.length > 0 ? adminQuestionsRef.current.map(x => x?.includes?.("This is milestone question") ? 2 : 1).reduce((a, b) => a + b, 0) : 0

    const [isloading, setIsLoading] = useState(false);
    const [typing, setTyping] = useState(false)
    const [recordedText, setRecordedText] = useState(
        'Speech to Text app created using React Native',
    );



    const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0); // Add state for recording time
    const [timer, setTimer] = useState(null); // Add state for timer reference

    const number = urlParams.get("number");
    const email = urlParams.get("email");

    useEffect(() => {
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [timer])

    // useEffect(() => {
    //   const keyboardDidShowListener = Keyboard.addListener(
    //     'keyboardDidShow',
    //     () => {
    //       scrollToEnd()
    //     }
    //   );

    //   return () => {
    //     keyboardDidShowListener.remove();
    //   };
    // }, []);

    const [options, setOptions] = useState(lang == 'en' ? (englishQuestion[0]?.split("\n")[1]?.split?.(',')?.filter?.((x) => x.trim()) ?? []) : (spanishQuestions[0]?.split("\n")[1]?.split?.(',')?.filter?.((x) => x.trim()) ?? []))
    const [compatibility, setCompatibility] = useState(0);
    // const [messages, setMessages] = useState([
    //   { role: 'AI', content: "Tus respuestas serán confidenciales y Kavan las utilizará para encontrar tu mejor match terapéutico. ¿Cómo quieres que te llame?" },
    // ]);

    const [messages, setMessages] = useState([
        {
            role: 'assistant',
            content: lang == 'en' ? englishQuestion[0]?.split("\n")[0] : spanishQuestions[0]?.split("\n")[0],
        }
    ]);

    const [typedMessages, settypedMessages] = useState('');
    const [encounters, setEncounters] = useState({});
    const [isInfo, setIsInfo] = useState(false);
    const [chatbotName, setChatBotName] = useState('')



    const updateTextArrayForName = (name) => {

        if (name != 'null') {
            NAME = name
            adminQuestionsRef.current = adminQuestionsRef.current.map(x => x?.split("{{name}}").join(`${name?.split("\"").join("")}`))
        }
    }


    const setActiveIndex = (val) => {
        if (typeof val == 'function') activeIndex.current = val(activeIndex.current)
        else activeIndex.current = val

    }


    const getNameFromAI = (conversation) => new Promise(resolve => {
        console.log("B.01")
        let content = `As per this conversation : ${conversation.map(x => `${x.role}: ${x.content}`).join('\n')}, can you please tell the user name and only the name. The name should be a string with no siffixes and prefixes. if user name not provided please return "null" with no siffixes and prefixes. please say nothing else`
        const data = {
            conversation: [{ role: "user", content }]
        }
        console.log("B.1")
        NETWORK_CALL(
            'POST',
            '/prompt',
            data,
            () => console.log(''),
            res => {
                console.log("B.2", res)

                if (res?.data?.response != "null" && res?.data?.response != "" && res?.data?.response != '"null"') {

                    updateTextArrayForName(res?.data?.response)
                    setActiveIndex(prv => prv + 1)

                    updateNameInRedux(res?.data?.response)
                    resolve()
                }
            },
            error => {


            }
        );
    })


    const scrollToEnd = () => {
        setTimeout(() => {
            if (flatListRef) {
                // flatListRef?.current.scrollToEnd({ animated: true });
                // scrollToEnd()
                [...document.getElementsByClassName('converstation-snippet')].at(-1).scrollIntoView()
            }
        }, 500)
    }




    const updateNameInRedux = (name) => {
        let final = name?.split('"').join("")
        setChatBotName(final)
    }


    const updateNameInDB = (name) => {
        const data = {
            name: name
        }
        NETWORK_CALL(
            'PATCH',
            '/user/updateProfile',
            data,
            console.log,
            res => {
                if (res?.status == 200) {
                    //   dispatch(userData(res?.data?.user));
                }
            },
            error => {

            },
            0,
            false,
            true,
        );
    }


    // const isFocused = useIsFocused()
    const isFocused = true;

    useEffect(() => {
        setCompatibility(0)

    }, [isFocused])





    const skipClicked = () => {
        chatBotApi()
    }


    const navigateToNext = () => {
        window.location.href = `https://kavan-web-liard.vercel.app?email=${email}&number=${encodeURIComponent(number)?.trim?.()}`;
    }

    const chatBotApi = () => {
        if (chatbotName) {
            updateNameInDB(chatbotName)
        }
        // dispatch(saveChatbotConversations(messages))
        // dispatch(saveChatbotRequested(true))
        navigateToNext()
        let data = {
          chat: messages,
        }

        NETWORK_CALL(
          'POST',
          '/chatbot',
          data,
          setIsLoading,
          res => {
            if (res.status == 200) {
            //   dispatch(chatBotData(data))
            //   dispatch(doctorsData(res?.doctors))
            //   dispatch(userData(res?.data?.user))
            //   BLACK_ALERT('Los datos del bot de chat se actualizaron correctamente');
            //   navigateToNext()
            window.location.href = `https://kavan-web-liard.vercel.app?number=${encodeURIComponent(number)}&email=${email}`
            } else {

            }
          },
          error => {


          }
        );
    }

    const isValidResponse = (q, a) => new Promise((resolve, reject) => {
        return resolve(false)
    })

    const rephraseFromAI = async (response, userResponse, encounters) => {

        let language = 'en'
        if (lang) {
            language = lang
        }

        const data = {
            conversation: [
                ...messages,
                { role: 'assistant', content: response },
                { role: 'user', content: userResponse },
                { role: 'system', content: `Generate an empathetic response asking a clarifying question considering the previous conversation. No suffixes or prefixes and do not specify yourself as an AI. Please rephrase in this language ${language == 'en' ? 'English' : 'Spanish'}.` },
            ]
        }

        const isValid = await isValidResponse(response, userResponse)
        if (isValid) {
            // setActiveIndex(x => x + 1)
            const e = {
                ...encounters,
                [activeIndex.current]: (encounters[activeIndex.current] ?? 0) + 1
            }
            setEncounters(e)
            setTyping(false)
            setMessages(messages => [
                ...messages,
                { role: 'assistant', content: adminQuestionsRef.current[activeIndex.current]?.split("\n")[0] },
            ])
            scrollToEnd()
        }
        else NETWORK_CALL(
            'POST',
            '/prompt',
            data,
            () => console.log(''),
            res => {
                if (res?.data?.response) {

                    setTyping(false)
                    setMessages(messages => [
                        ...messages,
                        { role: 'assistant', content: res?.data?.response },
                    ])
                    scrollToEnd()
                }

            },
            error => {
            }
        );
    }


    const summarizeQuestions = (q) => new Promise(resolve => {
        const conversation = milestoneQuestionsAsked.current
        const data = {
            conversation: [
                ...Object.entries(conversation).map(([k, v]) => [{ role: 'assistant', content: k }, { role: 'user', content: v }]).flat(),
                { role: 'user', content: `Given the previously stated conversation. Tell me how I, as a human feel, regardless of your existence as an AI. I do not need tips. Just summarize my feelings. Do not say "as an ai" or "i infer". no suffixes of prefixes needed. This summary must be in ${lang == 'en' ? 'English' : 'Spanish'}.` },
            ]
        }
        NETWORK_CALL(
            'POST',
            '/prompt',
            data,
            () => console.log(''),
            res => {
                if (res?.data?.response) {
                    console.log('okok', res)
                    resolve(res?.data?.response + q)
                }

            },
            error => {
                console.log('errrorrrrrr', error)
            }
        )
    })

    useEffect(() => {
        console.log("CHECK INDEX", activeIndex.current)
    }, [activeIndex.current])

    const sendMessage = async (option) => {
        // Voice.stop();
        setIsRecording(false);
        setTyping(true)
        setOptions([])
        const t = typedMessages
        settypedMessages('')
        setMessages(messages => [
            ...messages,
            { role: 'user', content: option == "" ? typedMessages : option },
        ])

        let questions = adminQuestionsRef.current
        const lastQWasMilestone = adminQuestionsRef.current[activeIndex.current]?.split?.('\n')?.[1]?.toLowerCase?.()?.includes?.('milestone')
        const qIsMilestone = adminQuestionsRef.current[activeIndex.current + 1]?.split?.('\n')?.[1]?.toLowerCase?.()?.includes?.('milestone')
        if (lastQWasMilestone && !qIsMilestone) {
            milestoneQuestionsAsked.current[adminQuestionsRef.current[activeIndex.current]?.split?.('\n')?.[0]?.toLowerCase?.()] = typedMessages
            const nqs = []
            let i = 0
            for (const q of adminQuestionsRef.current) {
                console.log("HAHAHOOO", activeIndex.current, i)
                if (activeIndex.current == i - 1) nqs.push(await summarizeQuestions(q))
                else nqs.push(q)
                i++
            }
            console.log("nqqqsss", nqs)
            adminQuestionsRef.current = nqs
            questions = nqs
            setActiveIndex(i => i + 1)
        }


        console.log("A", activeIndex.current)
        if (activeIndex.current == 0) setActiveIndex(x => x + 1)
        else if (activeIndex.current == 1) {
            await getNameFromAI([
                { role: 'assistant', content: lang == 'es' ? "cómo te llamas?" : "what is your name?" },
                { role: 'user', content: option == "" ? typedMessages : option },
            ])
        }
        scrollToEnd()

        console.log("B", activeIndex.current)
        if (adminQuestionsRef.current.length - 1 == activeIndex.current) {
            console.log("KHATAM HONA CHAYE")

            chatBotApi()
            return
        }

        console.log("ABHI BHIU CHAL RHA HAI")


        setCompatibility((activeIndex.current / lengthOfAdminQuestions) * (100))

        const e = {
            ...encounters,
            [activeIndex.current]: (encounters[activeIndex.current] ?? 0) + 1
        }
        setEncounters(e)

        console.log("C", NAME)
        if (NAME) {
            const [question, instructions] = adminQuestionsRef.current[activeIndex.current].split("\n")
            const isMilestone = instructions?.toLowerCase?.()?.includes?.('this is a milestone question') || instructions?.toLowerCase?.()?.includes?.('this is milestone question')
            if (isMilestone) {
                if (e[activeIndex.current] > 1) {
                    setActiveIndex(i => i + 1)
                    return rephraseFromAI(question, t, e)
                }
            } else {
                setActiveIndex(activeIndex.current + 1)
            }

            if (instructions?.toLowerCase?.()?.includes?.(',')) setOptions(instructions.split(',').map(x => x.trim()))

            setMessages(messages => [
                ...messages,
                { role: 'assistant', content: question },
            ])
        } else {

            console.log("activeIndex.current", activeIndex.current, adminQuestionsRef.current)
            setMessages(messages => [
                ...messages,
                { role: 'assistant', content: adminQuestionsRef.current[activeIndex.current].split("\n")[0] },
            ])
        }
        setTyping(false)
        scrollToEnd()




    }

    // Format recording time as "MM:SS"
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    return <>

        {/* <Navbar /> */}
        <div className="chatbot-container">
            <div className="chatbot-start-main" id="chatbot-start-main-id">
                <div id="chatbot-heading" style={{display:'flex',flexDirection:'row', justifyContent: chatbotName ? undefined : 'flex-start'}}>
                    <h1>KavanGPT</h1>
                    {chatbotName ? <h3 className='skipBot' onClick={()=>{chatBotApi()}}>Conocer ya a mi psicólogo</h3> : <div style={{ width: '105px' }}></div>}
                </div>
                <div>
                <h3 className='progressBarText'>Tu nivel de compatibilidad es del {Math.floor(compatibility)}%</h3>
                <div style={{width:'100%', background: 'white'}}>
                    <div style={{backgroundColor:"#99BEAD",height:5,width:`${compatibility}%`}}></div>
                </div>
                    
                </div>
                <div id="chatbot-text-main-conversation-container" className="chatbot-text-main-conversation-container">
                    {messages.map((m, i) => <div className="converstation-snippet" style={{ justifyContent: m?.role != "user" ? 'flex-start' : 'flex-end' }}>
                        {m?.role != "user" && <img src={kavanGPT} />}
                        <p style={{ marginBottom: messages.length - 1 == i ? 30 : undefined, background: m?.role != "user" ? undefined : '#F7F7FD', padding: m?.sender == "AI" ? undefined : '20px', borderRadius: 15, borderBottomRightRadius: 0 }}>{m?.content}</p>
                    </div>)}
                    {typing && <Typing />}
                </div>
                <div className="message-option-texts">
                    {options.map((ans, i) => <div className="message-text-option" onClick={() => {
                        sendMessage(ans)
                    }}>
                        <p>{ans.length > 12 ? `${ans.slice(0, 12)}...` : ans}</p>
                    </div>)}
                </div>
                <div className="bottom-messages">
                    <input type="text" placeholder="Escribe tu mensaje aqui" value={typedMessages} onChange={ev => settypedMessages(ev.target.value)} onKeyPress={ev => ev.key == "Enter" && document.querySelector('#send').click()} />
                    {isloading ? <div style={{ padding: 10, background: '#2e3b5b', borderRadius: 10 }}>
                        <ScaleLoader color="white" />
                    </div> : <img id="send" onClick={() => sendMessage(typedMessages)} src={sendChatbotPro} />}
                </div>
            </div>
        </div>
    </>;
};

const ChatBot = () => {
    const [spanishQuestions, setSpanishQuestions] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        authReq('GET', '/get-languages')
            .then(res => {
                setLoading(false)
                setSpanishQuestions(res?.esChatbot)
            })
    }, [])

    if(!spanishQuestions) return <></>
    if(loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>\
        <ScaleLoader color='black' />
    </div>
    return <ChatBotInner spanishQuestions={spanishQuestions}/>
}

export default ChatBot;


