import dayjs from "dayjs";
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { arrowRight, arrowRightTheme, clock, dummy2, threeDots, calenderWithDots } from "../../../assets";
import { authReq } from "../../../requests";
import Switch from "react-switch";
import Button from "@mui/material/Button";
import 'rc-time-picker/assets/index.css';
import "./appointments.css";
import SmallBtn from "../../../components/smallBtn/smallBtn";
import { getAllParams } from "../../../urlParams";
import { gapi } from "gapi-script"
import ApiCalendar from 'react-google-calendar-api';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { baseURL } from "../../../constants";
import cache from "../../../cache";
import socketIO from "socket.io-client";
import { fireDB } from "../../../firebase";

// import { doGAPIAction, initCalendar } from "../appointments/Appointments";
import DropDown from "../../../components/DropDown/DropDown";
import { Button as Buttton2, AppointmentView, SearchBar, TextInput, Loader } from "../../../components";
import moment from 'moment';
import "../rescheduleAppointment/rescheduleAppointment.css";
import { loginSet } from "../../../components/topNavbar";

const googleImage = "https://static.vecteezy.com/system/resources/previews/012/871/371/original/google-search-icon-google-product-illustration-free-png.png"

const months = {
  0: 'enero',
  1: 'febrero',
  2: 'marzo',
  3: 'abril',
  4: 'mayo',
  5: 'junio',
  6: 'julio',
  7: 'agosto',
  8: 'septiembre',
  9: 'octubre',
  10: 'noviembre',
  11: 'diciembre'
};

const monthMapping = {
  Jan: 'Enero',
  Feb: 'Febrero',
  Mar: 'Marzo',
  Apr: 'Abril',
  May: 'Mayo',
  Jun: 'Junio',
  Jul: 'Julio',
  Aug: 'Agosto',
  Sep: 'Septiembre',
  Oct: 'Octubre',
  Nov: 'Noviembre',
  Dec: 'Diciembre'
}

const dayMapping = {
  "Sun": "Domingo",
  "Mon": "Lunes",
  "Tue": "Martes",
  "Wed": "Miércoles",
  "Thu": "Jueves",
  "Fri": "Viernes",
  "Sat": "Sábado",
};

const spanishDays = Object.values(dayMapping)

const config = {
  "clientId": "475320902582-ahnmc5e4ssn96pn1ueevpec8so2s8tvp.apps.googleusercontent.com",
  "apiKey": "AIzaSyBK51BITyljFaXsBrhJd0_mg0uFjat_yZc",
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const timeZone = "UTC"

const apiCalendar = new ApiCalendar(config)

var appointmentStatusChanging = false
const setAppointmentStatusChanging = bool => {
  if (bool) appointmentStatusChanging = bool
  else setTimeout(() => {
    appointmentStatusChanging = bool
  }, 5000);
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const isPhone = () => getWindowDimensions().width < 421

class GoogleCalender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: null,
      status: "Sync",
    }
    this.handleItemClick = this.handleItemClick.bind(this)
    this.genDiv = this.genDiv.bind(this)

    this.interval = setInterval(async () => {
      const authStatus = gapi?.client?.getToken() != this.state.auth
      if (gapi && authStatus) {
        if (authStatus) {
          this.setState({ auth: gapi?.client?.getToken(), status: "Syncing" })
          try {
            await this.props.signIn()
            this.setState({ auth: gapi?.client?.getToken(), status: "In Sync" })
          } catch (_) {
            alert("Usage Limits Exceed")
            this.setState({ auth: gapi?.client?.getToken(), status: "Error" })
          }
        }
        else {
          this.setState({ auth: gapi?.client?.getToken(), status: "Signing Out" })
          this.props.signOut()
          this.setState({ auth: gapi?.client?.getToken(), status: "Sync" })
        }
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleItemClick(event, name) {
    try {
      if (name === 'sign-in') {
        apiCalendar.handleAuthClick()
      } else if (name === 'sign-out') {
        apiCalendar.handleSignoutClick()
      }
    } catch (e) {
      console.log(e)
    }
  }

  genDiv(name) {
    return <div
      onClick={(e) => this.handleItemClick(e, name)}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        border: "solid 1px lightgrey",
        borderRadius: "10px",
        padding: "10px",
        width: "160px",
        cursor: this.state.status === "Sync" ? "pointer" : "auto",
        marginBottom: "20px",
      }}
    >
      <img src={googleImage} style={{ width: '50px', height: 'auto' }} />
      <div style={{
        fontSize: "25px",
        margin: "10px",
        fontFamily: "Inter",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
        color: this.state.status === "Error" ? "red" : "black",
      }}>
        <div>{this.state.status}</div>
      </div>
    </div>
  }
  render() {
    return <>
      {gapi?.client?.getToken() === null ? this.genDiv("sign-in") : this.genDiv("sign-out")}
    </>
  }
}

function DatePicker(props) {
  const [isOpen, setIsOpen] = useState(props.open);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleCalendar = () => {
    setIsOpen(!isOpen); // Toggle the calendar's open/close state
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    toggleCalendar(); // Close the calendar after selecting a date
  };

  return (
    <Datetime
      locale="de"
      input={false}
      onBlur={props.onClick} // Toggle the calendar on text blur
      closeOnSelect
      value={props.selectedDate}
      onChange={props.onChange}
    />
  );
}

const addDays = function (date, days) {
  var date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
}

const addHours = function (date, hours) {
  var date = new Date(date);
  date.setHours(date.getHours() + hours);
  return date;
}

const formatDateYYMMDD = startDateTime => `${startDateTime.getFullYear()}-${startDateTime.getMonth() + 1}-${startDateTime.getDate()}/${startDateTime.getHours()}`

const let_ = (vars, f) => f(...vars)

const getAppointmentByDay = (date, hour, appointmentsGroupedByDay) => {
  console.log("dayhourapp+ab", appointmentsGroupedByDay, `${formatDateYYMMDD(date).split("/")[0]}/${hour.split(':')[0]}`, appointmentsGroupedByDay[`${formatDateYYMMDD(date).split("/")[0]}/${hour.split(':')[0]}`])
  return appointmentsGroupedByDay[`${formatDateYYMMDD(date).split("/")[0]}/${hour.split(':')[0]}`]
}

const dateByHours = (x) => {
  const d = new Date()
  d.setHours(x)
  return d
}

const getHours = d => parseInt(d.toString().split(" ")[4].split(":")[0])
const range = (start, end) => {
  const arr = []
  let i = 0
  for (let startDate = dateByHours(start); getHours(startDate) <= end; startDate = addHours(startDate, 1)) {
    if (i != 0 && startDate.getHours() == 0) break
    arr.push(startDate.getHours())
    if (arr.length > 25) break
    i++
  }
  return [...new Set(arr)]
}

function daysOfWeek(today) {
  // Calculate days since last Monday and days until next Sunday
  let daysSinceLastMonday = (today.getDay() + 6) % 7; // Days since the previous Monday
  let daysUntilNextSunday = (7 - today.getDay()) % 7;  // Days until the next Sunday

  // Calculate the last Monday
  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - daysSinceLastMonday);

  // Calculate the next Sunday
  const nextSunday = new Date(today);
  nextSunday.setDate(today.getDate() + daysUntilNextSunday);

  let dateRange = [];
  let currentDate = new Date(lastMonday);

  // Generate the date range from last Monday to next Sunday
  while (currentDate <= nextSunday) {
    dateRange.push(currentDate.toISOString().slice(0, 10)); // Format as "YYYY-MM-DD"
    currentDate.setDate(currentDate.getDate() + 1);
  }

  if(new Date(dateRange[0]).getDay() == 0) {
    const beginning = dateRange.slice(1)
    const x = new Date(dateRange.at(-1))
    x.setDate(x.getDate() + 1)
    beginning.push(x.toISOString().slice(0, 10))

    dateRange = beginning
  }


  return dateRange;
}

const createTimeSlots = (fromTime, toTime) => {
  let startTime = moment(fromTime, 'HH:mm');
  let endTime = moment(toTime, 'HH:mm');
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }
  let arr = [];
  while (startTime <= endTime) {
    arr.push(new moment(startTime).format('HH:mm'));
    startTime.add(60, 'minutes');
  }
  return arr.map((startTime, index) => {
    return {
      label: `${startTime}`,
      id: index + 1
    };
  });
}

const baseArray = new Array(24).fill(0).map((_, i) => i + 1 > 12 ? `${i + 1 - 12}:00 PM` : `${i + 1}:00 AM`).map(x => { return { label: x, value: x } })
console.log("Times", baseArray)

const RescheduleAppointment = (props) => {
  const params = props
  let { id, free, clearForm, rescheduleFunction, setRescheduleModal } = props
  id = id ? id : props?.app?.id
  free = free ? free : props?.app?.app?.slot?.includes?.(':45')
  const type = free ? "free" : "paid"
  console.log("rescheduleprops", props)
  const navigate = useNavigate();
  const [user, setUser] = useState({})
  const [date, setDate] = useState(new Date().toISOString().split('T')[0])
  const [reason, setReason] = useState("")
  const [loading, setLoading] = useState(true)
  const [duration, setDuration] = useState(free ? 15 : 45)
  const [doctorStartTime, setDoctorStartTime] = useState(0)
  const [doctorEndTime, setDoctorEndTime] = useState(0)
  const [appointments, setAppointments] = useState(props.appointments)
  const [isSubmit, setIsSubmit] = useState(false);

  const [times, setTimes] = useState(baseArray)
  const [timeSlots, setLocalTimeSlots] = useState(null)
  const [time, setSelectedTime] = useState({
    label: "",
    value: "",
  })

  const isDisabledTime = itemT => {
    if (!itemT.label) return true
    const properMin = free ? '45' : '00'
    const newItemT = { id: itemT.id, label: itemT.label.replace(':00', `:${properMin}`) }
    const dateTimeString = `${new Date(date).toISOString().split('T')[0]}T${newItemT.label}:00Z`
    const itemDate = new Date(dateTimeString)
    console.log("RXDATE", itemDate, new Date())
    if (itemDate.getTime() < Date.now()) return true
    // if (parseInt(newItemT.label.split(':')[0]) < new Date().getHours() + 1 && new Date().toISOString().split('T')[0] == new Date(date).toISOString().split('T')[0]) return true
    const itemDateFound = appointments.filter(x => x.actualStatus !== 'patient-canceled').find(app => {
      const appointmentStartDate = app.appointmentStart - new Date().getTimezoneOffset() * 60_000
      return appointmentStartDate == itemDate.getTime()
    })
    console.log("hw123w", newItemT, itemDateFound)
    return !!itemDateFound
  }

  const createSlotsOfDay = (dayNumber) => {
    if (user?.weeklyAvailability?.length > 0) {
      let week = [...user?.weeklyAvailability]
      let dayObj = week.find((item) => item?.weekDay == dayNumber)

      if (dayObj.availability) {
        setTimes(generateTimeSlots(dayObj).filter(x => !isDisabledTime(x)))
      } else {
        setTimes([])
      }

    }
  }


  function generateTimeSlots(availability) {
    let { startTime, endTime } = availability;
    console.log(
      "cdxyz123",
      `${new Date().toISOString().split('T')[0]}T${startTime?.trim?.()}`,
      new Date(
        `${new Date().toISOString().split('T')[0]}T${startTime?.trim?.()}`
      ).getTime(),
      new Date(
        new Date(
          `${new Date().toISOString().split('T')[0]}T${startTime?.trim?.()}`
        ).getTime() + new Date().getTimezoneOffset() * 60_000
      ),
      new Date(
        new Date(
          `${new Date().toISOString().split('T')[0]}T${startTime?.trim?.()}`
        ).getTime() + new Date().getTimezoneOffset() * 60_000
      ).toISOString()
    )
    startTime = new Date(
      new Date(
        `${new Date().toISOString().split('T')[0]}T${startTime?.trim?.()}`
      ).getTime() - new Date().getTimezoneOffset() * 60_000
    ).toISOString()
    endTime = new Date(
      new Date(
        `${new Date().toISOString().split('T')[0]}T${endTime?.trim?.()}`
      ).getTime() - new Date().getTimezoneOffset() * 60_000
    ).toISOString()

    // Helper function to convert time string to Date object with today's date
    function timeStringToDate(timeString) {
      const [hours, minutes] = timeString.trim().split(':').map(Number);
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
    }

    // Convert startTime and endTime to Date objects
    const startDate = startTime;
    const endDate = new Date(endTime)

    // Initialize array to hold time slots
    const timeSlots = [];

    // Iterate through each hour between startTime and endTime
    let current = new Date(startDate);
    if (type == "free") {
      while (current < endDate) {
        // Format the hour slot
        const hourLabel = `${current.getHours().toString().padStart(2, '0')}:45`;
        console.log(hourLabel)
        timeSlots.push({
          id: timeSlots.length + 1,
          label: hourLabel
        });

        // Move to the next hour
        current.setHours(current.getHours() + 1);
      }
    } else {
      console.log("cdxyz", current, endDate)
      while (current <= endDate) {
        // Format the hour slot
        const hourLabel = `${current.getHours().toString().padStart(2, '0')}:${current.getMinutes().toString().padStart(2, '0')}`;
        console.log(hourLabel)
        timeSlots.push({
          id: timeSlots.length + 1,
          label: hourLabel
        });

        // Move to the next hour
        current.setHours(current.getHours() + 1);
      }
    }

    return timeSlots;
  }

  console.log("times.times", times)
  const formatTime = (date, time) => {
    const slot = `${time} ${date}`;
    return slot
  }

  useEffect(() => {
    authReq('GET', '/user/me')
      .then(data => setUser(data.data))
  }, [])

  useEffect(() => {
    authReq('GET', '/appointment?onlyTimes=true')
      .then(data => {
        setLoading(false)
        console.log("app0app", id, data.appointments)
        const singleAppointment = data.appointments.find(app => app._id == id)
        setAppointments(data.appointments.filter(x => x.status !== 'patient-canceled').filter(x => x.status !== 'patient-completed').map(app => {
          const startDate = new Date(app.appointmentStart).toISOString()
          const endDate = new Date(app.appointmentEnd).toISOString()
          console.log(startDate, endDate, app.status)
          return { ...app }
        }))
        const selectedTime = new Date(singleAppointment.appointmentStart - new Date().getTimezoneOffset() * 60_000).toISOString().split("T")[1].split(":").slice(0, 2).join(":")
        console.log("SELECTIME", selectedTime)
        setDate(new Date(singleAppointment.appointmentStart).toISOString().split("T")[0])
        setSelectedTime({
          label: selectedTime,
          value: selectedTime
        })
      })
  }, [])

  useEffect(() => {
    console.log("Ummmm")
    const existsArr = appointments.filter(app => app.slot === formatTime(date, time))
    console.log(appointments)
    if (existsArr.length > 0) console.log("Exists")
  }, [date, time])

  useEffect(() => {
    createSlotsOfDay(new Date(date).getDay())
  }, [date, user, appointments])

  console.log("okmosn", free, props?.app?.app?.slot?.includes?.(':45'),)

  return (
    <div className="kavan_admin_main_container shadow-container reschedule-container" onClick={ev => ev.stopPropagation()} style={{ marginTop: -70, zIndex: 150, padding: '2rem', height: '50vh', width: '45vw', overflow: 'visible' }}>
      {(
        <>
          <h1 className="kwn-heading">Cambiar Cita </h1>
          <div className="kwn_reschedule_appointment_reasons_top_view">
            <h2>¿Cuál es el motivo del cambio de la cita?</h2>
            <div className="kwn_reschedule_appointment_reasons_view">
              <TextInput
                type={"text"}
                value={reason}
                onChange={ev => setReason(ev.target.value)}
                inputStyle={{ fontSize: 20 }}
                style={{ borderRadius: 10, width: "97%", paddingTop: 13, paddingBottom: 13 }}
                placeholder={"Me ha surgido un imprevisto personal..."}
              />
            </div>
            <div className="kwn_reschedule_appointment-nueva">
              <h1>Programar nueva cita</h1>
            </div>
            <div className="kwn_reschedule_appointment_inputs_top_view">
              <TextInput
                type={"date"}
                value={date}
                hideLabel={true}
                onChange={ev => {
                  setDate(ev.target.value)
                  setSelectedTime('')
                  // setTimeout(() => {
                  //   setSelectedTime({
                  //     label: times[0]?.label?.replace(':00', free ? ':45' : ':00'),
                  //     value: times[0]?.label?.replace(':00', free ? ':45' : ':00'),
                  //   })
                  // }, 500)
                }}
                inputStyle={{ fontSize: 20, color: '#056038' }}
                style={{ width: "20rem", marginRight: '25px', marginTop: -7, height: 33 }}
                title={"Select Date"}
              />
              <DropDown
                hideLabel={true}
                text={"Time"}
                style={{ color: '#056038', height: '22vh', }}
                bg={times.length == 0 ? {
                  opacity: "0.4",
                  width: '20rem',
                  height: '18vh',
                  marginRight: 25,
                  marginTop: -8,
                } : {
                  width: '20rem',
                  height: '18vh',
                  marginRight: 25,
                  marginTop: -8,
                }}
                options={times.map(time => time.label.split(':00').join(free ? ':45' : ':00'))}
                selected={time ? time : times[0]?.label?.split(':00').join(free ? ':45' : ':00')}
                setSelected={setSelectedTime}
              />
              {times.length == 0 && <p style={{ color: "red", marginLeft: "1rem" }}>No timeslots available on {new Date(date).toDateString()}</p>}
              {/* <TextInput
                type={"number"}
                value={duration}
                onChange={ev => setDuration(ev.target.value)}
                placeholder={"30"}
                inputStyle={{ fontSize: 20 }}
                style={{ width: "100%" }}
                title={"Select Duration (Mins)"}
              /> */}
              <Buttton2 disabled={(!date) || (!time)} style={{ marginTop: -50 }} onClick={() => {
                clearForm()
                const f = () => async () => {

                  console.log("mmmmmmm a")
                  const properMin = free ? '45' : '00'
                  console.log("mmmmmmm a1")
                  const dateTimeString = `${new Date(date).toISOString().split('T')[0]}T${(time.label ?? time).replace(':00', `:${properMin}`)}:00Z`
                  console.log("mmmmmmm a2")
                  const appointmentStart = new Date(dateTimeString).getTime() + new Date().getTimezoneOffset() * 60_000
                  console.log("mmmmmmm a3")
                  const appointmentEnd = appointmentStart + (duration * 60_000)
                  console.log("mmmmmmm a4")
                  const newSlot = formatTime(date, time.label ?? time)
                  console.log("mmmmmmm CALLSTRING", dateTimeString, newSlot)

                  setLoading(true)
                  console.log(params)
                  await authReq('PATCH', `/appointment/${params.app?.app?._id}`, {
                    status: 'patient-reschedule',
                    rescheduleAppointmentStart: appointmentStart,
                    rescheduleAppointmentEnd: appointmentEnd,
                    rescheduleRequestedBy: user?._id,
                    slot: newSlot,
                    cancelationReason: reason,
                  })
                  doGAPIAction(async gapi => {
                    const appointment = appointments.find(app => app._id == params.id)
                    const calendarId = await initCalendar()
                    if (appointment.eventId) await gapi.client.calendar.events.update({
                      calendarId,
                      eventId: appointment.eventId,
                      resource: {
                        summary: appointment.patient?.name ? `Appointment with ${appointment.patient?.name}` : "Appointment",
                        start: {
                          dateTime: new Date(appointmentStart).toISOString(),
                          timeZone: "UTC",
                        },
                        end: {
                          dateTime: new Date(appointmentEnd).toISOString(),
                          timeZone: "UTC",
                        }
                      },
                    })
                    else await gapi.client.calendar.events.insert({
                      calendarId,
                      resource: {
                        summary: appointment.patient?.name ? `Appointment with ${appointment.patient?.name}` : "Appointment",
                        start: {
                          dateTime: new Date(appointmentStart - new Date().getTimezoneOffset() * 60_000).toISOString(),
                          timeZone: "UTC",
                        },
                        end: {
                          dateTime: new Date(appointmentEnd - new Date().getTimezoneOffset() * 60_000).toISOString(),
                          timeZone: "UTC",
                        }
                      },
                      conferenceDataVersion: 1,
                    })
                  })
                  setLoading(false)

                  setIsSubmit(true)
                }
                rescheduleFunction.current = f
                setRescheduleModal(() => f)
              }}>
                Continuar
              </Buttton2>
            </div>
            {/* <div className="kwn_reschedule_appointment_button_view">

            </div> */}
          </div>
        </>
      )}
    </div>
  )
}

const minMaxHours = weeklyAvailability => {
  if (!weeklyAvailability?.length) return range(1, 24).map(x => `${x}:00`)
  console.log('ssssseeeeeeeeeeee 11111111', weeklyAvailability)
  const [s] = weeklyAvailability.sort((a, b) => a.startTime > b.startTime ? 1 : -1).filter(x => x?.availability)
  const [e] = weeklyAvailability.sort((a, b) => a.endTime > b.endTime ? -1 : 1).filter(x => x?.availability)
  const [s1, e1] = [
    parseInt(s.startTime.split(':')[0]) - new Date().getTimezoneOffset() / 60,
    parseInt(e.endTime.split(':')[0]) - new Date().getTimezoneOffset() / 60,
  ]
  console.log('ssssseeeeeeeeeeee', s1, e1)

  return range(s1, e1).map(x => `${x}:00`)
}

const dayHours = (weeklyAvailability, d) => {
  if (!weeklyAvailability?.length) return range(1, 24).map(x => `${x}:00`)
  console.log('ssssseeeeeeeeeeee 11111111', d, weeklyAvailability)
  const { startTime, endTime } = weeklyAvailability.find(x => x.weekDay == d)
  const [s1, e1] = [
    parseInt(startTime.split(':')[0]) - new Date().getTimezoneOffset() / 60,
    parseInt(endTime.split(':')[0]) - new Date().getTimezoneOffset() / 60,
  ]
  console.log('ssssseeeeeeeeeeee', s1, e1)

  return range(s1, e1).map(x => `${x}:00`)
}

const Scheduler = props_ => {
  const { date, setUserLoading, setDate, user, setCancalationModal, setRescheduling, expandedAppointment, setExpandedAppointment } = props_

  const ref = useRef(0)
  const dailyRef = useRef(0)
  const weeklyRef = useRef(0)

  const setAppointments = props_.setSchedulerData
  const appointments = props_.schedulerData
  const calendarInput = useRef(null)
  const [pickerShown, setPickerShown] = useState(false)
  const [activeTab, setActiveTab] = useState(window.location.href.split('?tab=')?.[1] ?? 'weekly')
  const [tooltip, setTooltip] = useState(null)
  const [currentTooltip, setCurrentTooltip] = useState(null)
  const [isChanged, setIsChanged] = useState(null)
  const [weeklyAvailability, setWeeklyAvailability] = useState([])
  const navigate = useNavigate()
  const currentDate = Date.now()
  const small = false
  const times = new Array(24).fill(0).map((_, i) => i).map(x => `${x <= 9 ? `0${x}` : x}:00`).map(x => ({ label: x, value: `${x}:00` }))
  const [timeData, setTimeData] = useState([
    {
      weekDay: 1,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    },
    {
      weekDay: 2,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    },
    {
      weekDay: 3,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    },
    {
      weekDay: 4,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    },
    {
      weekDay: 5,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    },
    {
      weekDay: 6,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    },
    {
      weekDay: 0,
      startTime: "09:00:00",
      endTime: "18:00:00",
      availability: false
    }
  ])
  console.log(props_.startingHour, props_.endingHour)
  console.log()
  const [hours, setHours] = useState(range(
    1,
    24
  ).map(x => `${x}:00`))

  const props = JSON.parse(JSON.stringify(props_))
  props.schedulerData = typeof props.schedulerData == 'string' ? JSON.parse(props.schedulerData).map(modifyAppointmentsForUI) : props.schedulerData
  console.log("datastts", props.schedulerData)

  const convertToUTC = (time, op = (a, b) => a + b) => {
    //  console.log("timeeee", time)
    const t1 = new Date(new Date().toISOString().split('T')[0] + "T" + time?.slice?.(0, 5) + ":00").getTime()
    const t2 = new Date().getTimezoneOffset() * 60_000
    return new Date(op(t1, t2)).toTimeString().split('GM')[0]
  };


  const getDayName = (number) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[number];
  };

  const updateTime = (start, end, status, index) => {
    setIsChanged(true)
    let data = [...timeData];
    data[index] = {
      weekDay: timeData[index].weekDay,
      startTime: start,
      endTime: end,
      availability: status
    };
    setTimeData([...data]);
  };

  const updateScheduleApi = (data) => {
    let body = {
      weeklyAvailability: data
    }
    authReq("PATCH", "/user/updateProfile", body).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err);
    });
  }


  const handleSaveChanges = () => {
    setIsChanged(false)
    console.log('UTC Time Data:', timeData[4]);
    const utcTimeData = timeData.map(item => ({
      ...item,
      startTime: convertToUTC(item.startTime),
      endTime: convertToUTC(item.endTime),
    }
    ));
    updateScheduleApi(utcTimeData)
    // You can now send `utcTimeData` to your server
  };

  const readyScheduleData = (response) => {
    try {
      const updatedTimeData = timeData.map(item => {
        const matchingResponse = response.find(resItem => resItem.weekDay === item.weekDay);
        if (matchingResponse) {
          return {
            ...item,
            startTime: convertToUTC(matchingResponse.startTime?.slice?.(0, 5), (a, b) => a - b),
            endTime: convertToUTC(matchingResponse.endTime?.slice?.(0, 5), (a, b) => a - b),
            availability: matchingResponse.availability
          };
        }
        return item;
      });

      setWeeklyAvailability(updatedTimeData)
      setTimeData(updatedTimeData);
    } catch (e) {
      loginSet("")
      localStorage.clear()
    }
  };

  const makeLines = apps => {
    const napps = [
      ...apps.filter(app => app.appointmentStart < Date.now()),
      apps.find(app => app.appointmentStart >= Date.now()) ? { ...apps.find(app => app.appointmentStart >= Date.now()), line: true } : undefined,
      ...apps.filter(app => app.appointmentStart >= Date.now()).slice(1),
    ].filter(Boolean)
    console.log("napps.napps", napps)
    return napps
  }

  useEffect(() => {
    console.log("sssssssss sssssssss ssssssss 11111 11111")
    authReq("GET", "/user/me").then((res) => {
      console.log("sssssssss sssssssss sssssssss")
      setWeeklyAvailability(res?.data?.weeklyAvailability)

      if (activeTab == 'weekly') setHours(minMaxHours(res?.data?.weeklyAvailability))
      else if (activeTab == 'daily') setHours(dayHours(res?.data?.weeklyAvailability, new Date(date).getDay()))

      localStorage.setItem('user', JSON.stringify(res));
      readyScheduleData(res?.data?.weeklyAvailability)
    }).catch((err) => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    const f = (res) => {
      setUserLoading(true)
      if (activeTab == 'weekly') setHours(minMaxHours(res?.data?.weeklyAvailability))
      else if (activeTab == 'daily') setHours(dayHours(res?.data?.weeklyAvailability, new Date(date).getDay()))
      else setHours(range(1, 24).map(x => `${x}:00`))
      setUserLoading(false)
    }
    if (!weeklyAvailability?.length) f({ data: { weeklyAvailability } })
    else authReq("GET", "/user/me").then(f)
  }, [activeTab, date])

  const appointmentsGroupedByDay = {}
  for (const app of props.schedulerData) {
    const startDateTime = new Date(app?.rescheduleAppointmentStart ?? app.startDate)
    const startDate = formatDateYYMMDD(startDateTime)
    // console.log("stdt", app.startDate)
    if (!appointmentsGroupedByDay[startDate]) appointmentsGroupedByDay[startDate] = [app]
    else appointmentsGroupedByDay[startDate].push(app)
  }
  for (const k in appointmentsGroupedByDay) {
    const apps = appointmentsGroupedByDay[k]
    // appointmentsGroupedByDay[k] = [...apps.filter(x => x?.status === undefined), ...apps.filter(x => x?.status == "join" || x?.status == "none")].filter(x => !!x)
    appointmentsGroupedByDay[k] = apps
      ?.sort?.((a, b) => a?.actualStatus == "patient-canceled" && b?.actualStatus == "patient-pending" ? 1 : -1)
      ?.map?.(x => ({ ...x, free: x?.startDate?.includes?.(':45') }))
  }

  const getMMYY = x => `${new Date(x).getDate()}-${new Date(x).getMonth()}-${new Date(x).getFullYear()}`
  const appointmentsGroupedByMonth = {}
  for (const app of appointments) {
    const startDateTime = new Date(app?.rescheduleAppointmentStart ?? app.startDate)
    const startDate = getMMYY(startDateTime)
    // console.log("stdt", app.startDate)
    if (!appointmentsGroupedByMonth[startDate]) appointmentsGroupedByMonth[startDate] = [app]
    else appointmentsGroupedByMonth[startDate].push(app)
  }
  for (const k in appointmentsGroupedByMonth) {
    const apps = appointmentsGroupedByMonth[k]
    appointmentsGroupedByMonth[k] = apps.filter(Boolean).filter(x => (x?.rescheduleAppointmentStart ? x?.rescheduleAppointmentStart : x.appointmentStart) >= new Date(date).getTime())
    .sort((a, b) => (a?.rescheduleAppointmentStart ? a?.rescheduleAppointmentStart : a?.appointmentStart) > (b?.rescheduleAppointmentStart ? b?.rescheduleAppointmentStart : b?.appointmentStart) ? 1 : -1)
    // appointmentsGroupedByMonth[k] = [
    //   ...apps.filter(x => x?.status === undefined),
    //   ...apps.filter(x => x?.status == "join" || x?.status == "none"),
    // ]
    //   .filter(x => !!x)
  }

  console.log("appointmentsGroupedByMonth", appointmentsGroupedByMonth)

  console.log("APPOINTMENTS-GROUPED", appointmentsGroupedByDay)
  ref.current = 0
  dailyRef.current = 0
  weeklyRef.current = 0

  return <div className="scheduler-container" style={{ width: activeTab == 'agenda' ? '96vw' : undefined }} onClick={() => setTooltip(null)}>



    <div className="scheduler-mainbar satoshi">
      <div className="scheduler-topbar">
        <div style={{ display: "flex", alignItems: 'center' }} >
          <div id="arrowRight" className="today-button" onClick={() => setDate(addDays(date, activeTab == 'daily' ? -1 : -7))}>
            <img className="arrowRight" src={arrowRightTheme} />
          </div>
          <h1 onClick={() => setPickerShown(!pickerShown)}>
            {date.toDateString().split(' ').map(x => monthMapping[x] ? monthMapping[x] : x).map(x => dayMapping[x] ? dayMapping[x] : x).join(' ')}
          </h1>
          <div id="arrowLeft" className="today-button" onClick={() => setDate(addDays(date, activeTab == 'daily' ? 1 : 7))}>
            <img className="arrowLeft" src={arrowRightTheme} />
          </div>
          <div className="today-button" style={{ width: '80px' }} onClick={() => setDate(new Date())}>
            <p>Today</p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="today-button" onClick={() => {
            setActiveTab('agenda')
            if (activeTab != 'agenda') setDate(new Date())
          }}>
            <p>Agenda</p>
          </div>
          <div className="today-button" onClick={() => setActiveTab('daily')}>
            <p>Day</p>
          </div>
          <div className="today-button" onClick={() => setActiveTab('weekly')}>
            <p>Week</p>
          </div>
        </div>
        <div>
          <div className="today-button" onClick={() => setActiveTab('availability')} style={{ marginRight: 75 }}>
            <p>Mi disponibilidad</p>
          </div>
        </div>
        {/* <div className="today-button" onClick={() => setDate(new Date())}>
          <p>Today</p>
        </div> */}
        <div className="calendar-main">{pickerShown && <DatePicker opened={pickerShown} selected={date} onChange={date => {
          const date2 = new Date(date.format('MM-DD-YYYY'))
          console.log("selectdate", date2)
          setDate(date2)
          setPickerShown(false)
        }} />}</div>
      </div>

    </div>

    {activeTab == 'availability' ? <div className="weekly-schedule">
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
        <h2 style={{ marginBottom: 8, width: '80vw' }} className='user-name-2' >Weekly Availabilty</h2>
        <table style={{ width: '80vw' }}>
          <thead>
            <tr style={{ width: '100%', background: '#f2f2f2' }}>
              <th className='bio-label' >Day</th>
              <th className='bio-label' >Start Time</th>
              <th className='bio-label'>End Time</th>
              <th className='bio-label' ></th>
            </tr>
          </thead>
          <tbody style={{ border: '1px solid #f2f2f2' }}>
            {timeData.map((item, index) => (
              <tr key={item.weekDay}>
                <td className='bio-text' >{getDayName(item.weekDay)}</td>
                <td>
                  <DropDown
                    hideLabel={true}
                    text={"Time"}
                    disabled={!item?.availability}
                    style={{ color: '#056038', height: '22vh', }}
                    options={times.filter(({ label }) => label < timeData[index]?.endTime).map(time => false ? ({ label: time.label.replace(':00', ':45'), value: time.label.replace(':00', ':45') }) : time)}
                    selected={item?.startTime?.slice?.(0, 5)?.endsWith?.(':') ? item?.startTime?.slice?.(0, 4) : item?.startTime?.slice?.(0, 5)}
                    setSelected={data => updateTime(data?.value, item?.endTime, true, index)}
                  />
                </td>
                <td>
                  <DropDown
                    hideLabel={true}
                    text={"Time"}
                    disabled={!item?.availability}
                    style={{ color: '#056038', height: '22vh', }}
                    options={times.filter(({ label }) => label > timeData[index]?.startTime).map(time => false ? ({ label: time.label.replace(':00', ':45'), value: time.label.replace(':00', ':45') }) : time)}
                    selected={item?.endTime?.slice?.(0, 5)?.endsWith?.(':') ? item?.endTime?.slice?.(0, 4) : item?.endTime?.slice?.(0, 5)}
                    setSelected={data => updateTime(item?.startTime, data?.value, true, index)}
                  />
                </td>
                {/* <td><input style={{ borderRadius: 4 }} className='bio-text' max={item?.endTime} disabled={!item?.availability} value={item?.startTime?.slice?.(0, 5)} type="time" onChange={(data) => { updateTime(data.target.value, item?.endTime, true, index) }} /></td> */}
                {/* <td><input style={{ borderRadius: 4 }} className='bio-text' min={item?.startTime} disabled={!item?.availability} value={item?.endTime?.slice?.(0, 5)} type="time" onChange={(data) => { updateTime(item?.startTime, data.target.value, true, index) }} /></td> */}
                <td><Switch onColor={"#006039"} uncheckedIcon={false} checkedIcon={false} onChange={(data) => { updateTime(item?.startTime, item?.endTime, data, index) }} checked={item?.availability} /></td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          isChanged &&
          <div style={{ width: '80vw' }}>
            <div onClick={handleSaveChanges} style={{ cursor: 'pointer', marginTop: 50, paddingRight: 35, paddingLeft: 35, paddingTop: 15, paddingBottom: 15, width: 130, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', borderRadius: 10, fontFamily: 'Satoshi', fontSize: 13, fontWeight: 500, background: '#056038' }} >
              <p style={{ whiteSpace: 'nowrap', color: 'whitesmoke' }}>Save</p>
            </div>
          </div>

        }
      </div>
    </div> : activeTab == 'agenda' ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 30 }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
        <h3 style={{ width: '94vw', fontFamily: 'Satoshi', fontSize: 16, fontWeight: 'bold', marginLeft: '5vw' }}>Próximas sesiones</h3>
      </div>
      <div style={{ zoom: 0.9, display: 'flex', justifyContent: 'center' }}>
        <div className="scheduler-content-container satoshi dark-color font-15" style={{ width: '90%', marginTop: '3vh', borderTop: '1px solid #98B4B5', borderBottom: '1px solid #98B4B5', flexDirection: 'column', color: '#8F8F8F' }}>
          <div style={{ borderBottom: '1px solid #98B4B5', display: 'flex', padding: '20px', height: '6vh', alignItems: 'center', justifyContent: 'space-between', color: '#8F8F8F', paddingBottom: 1, paddingTop: 1 }}>
            <p style={{ width: '150px', fontSize: 17 }}>
              {<span style={{ marginRight: '10px', fontSize: 16, color: '#8F8F8F' }}>Fecha</span>}
            </p>
            <p style={{ width: 180, fontSize: 17, color: '#8F8F8F' }}>Horario</p>
            <div style={{ display: 'flex', paddingLeft: '10px', alignItems: 'center', borderRadius: 10, height: 45, width: '110px', fontSize: 20 }}>
              <p style={{ width: '30px', marginLeft: '5px', color: '#8F8F8F', fontSize: 17 }}>Paciente</p>
            </div>
            <div style={{ width: 240 }}>
              <p style={{
                fontSize: 17,
                padding: 10,
                color: '#8F8F8F'
              }}>Status de sesión</p>
            </div>

            <p style={{ width: 150, fontSize: 17 }}></p>
          </div>
          {[...Object.entries(appointmentsGroupedByMonth)
            .sort(([a, _1], [b, _2]) => a > b ? 1 : -1)
            .sort(([_1, [a]], [_2, [b]]) => (a?.rescheduleAppointmentStart ? a?.rescheduleAppointmentStart : a?.appointmentStart) > (b?.rescheduleAppointmentStart ? b?.rescheduleAppointmentStart : b?.appointmentStart) ? 1 : -1)
          ]
            .reverse()
            .map(([month, appointments], i) => appointments.length ? <div style={{ borderBottom: Object.entries(appointmentsGroupedByMonth)?.length - 1 == i ? undefined : '1px solid #98B4B5' }}>
              {
                makeLines(
                  appointments
                    .filter(x => x?.status != 'patient-canceled')
                    .sort((a, b) => new Date(a?.appointmentStart).toISOString().split('T')[1].split('.')[0].slice(0, 5) > new Date(b?.appointmentStart).toISOString().split('T')[1].split('.')[0].slice(0, 5) ? 1 : -1)
                    .filter(app => new Date(app?.appointmentStart).getMonth() == new Date().getMonth())
                )
                .sort((a, b) => (a?.rescheduleAppointmentStart ? a?.rescheduleAppointmentStart : a?.appointmentStart) > (b?.rescheduleAppointmentStart ? b?.rescheduleAppointmentStart : b?.appointmentStart) ? 1 : -1)
                .map((app, i) => <div className="font-15">
                  {console.log("weektime", app, app?.actualStatus)}
                  <div style={{ display: 'flex', padding: '20px', height: '7vh', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p style={{ width: '150px', fontSize: 15 }}>
                      {i == 0 && <span style={{ marginRight: '10px', fontSize: 15, fontWeight: 'bold' }}>{spanishDays[new Date(app?.rescheduleAppointmentStart ? app?.rescheduleAppointmentStart : app?.appointmentStart).getDay()]} {new Date(app?.rescheduleAppointmentStart ? app?.rescheduleAppointmentStart : app?.appointmentStart).toISOString().split('T')[0].split('-')[2]}, {months[month.split('-')[1]]?.[0]?.toUpperCase?.()}{months[month.split('-')[1]]?.slice?.(1)?.toLowerCase?.()}</span>}
                    </p>
                    <p style={{ width: 180, fontSize: 15, fontWeight: 'bold' }}>{new Date((app?.rescheduleAppointmentStart ?? app?.appointmentStart) - new Date().getTimezoneOffset() * 60_000).toISOString().split('T')[1].split('.')[0].slice(0, 5)} - {new Date((app?.rescheduleAppointmentEnd ?? app?.appointmentEnd) - new Date().getTimezoneOffset() * 60_000).toISOString().split('T')[1].split('.')[0].slice(0, 5)}</p>
                    <div style={{ display: 'flex', paddingLeft: '10px', alignItems: 'center', borderRadius: 10, height: 45, width: '110px', fontSize: 20 }}>
                      <div className="user-image2" style={{ padding: 0, marginRight: '5px', background: `#${app?.patient?._id?.slice?.(0, 6)}`, color: 'white' }}>{app?.patient?.name?.[0]?.toUpperCase?.()}{app?.patient?.name?.[1]?.toLoweCase?.()}</div>
                      <p style={{ width: '30px', marginLeft: '5px', fontSize: 17, fontWeight: 600 }}>{app?.patient?.name?.split?.(' ')?.[0]}</p>
                    </div>
                    <div style={{ width: 240 }}>
                      <p style={{
                        fontSize: 15,
                        background: app?.actualStatus == 'patient-canceled' ? 'rgb(255 171 171 / 44%)' : app?.actualStatus == 'patient-pending' ? '#FFF1DD' : '#effbf4',
                        color: app?.actualStatus == 'patient-canceled' ? 'red' : app?.actualStatus == 'patient-pending' ? '#FF9E14' : app?.actualStatus == 'patient-upcoming' || app?.actualStatus == 'patient-reschedule' ? '#2BAB60' : (app?.actualStatus == 'patient-pending' ? '#fff1dd' : '#eff2fb'),
                        borderRadius: 5,
                        padding: 10,
                      }}>{
                          app?.actualStatus == 'patient-pending' ? <span style={{ display: 'flex', alignItems: 'center' }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g id="24 / notifications / alert-triangle">
                                <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M18.7046 13.2943L12.8668 3.3147C12.2807 2.29869 11.1884 1.66807 10.007 1.66669C8.82556 1.66531 7.73282 2.29332 7.13945 3.31474L1.2995 13.298C0.693942 14.3162 0.680243 15.5921 1.2668 16.6327C1.85384 17.6741 2.95303 18.3222 4.14865 18.3319L15.8469 18.332C17.0549 18.3202 18.152 17.6741 18.739 16.6336C19.3252 15.5944 19.3121 14.3213 18.7046 13.2943ZM2.73505 14.1448L8.57932 4.15411C8.87529 3.64464 9.41812 3.33267 10.0051 3.33336C10.592 3.33404 11.1346 3.6473 11.4257 4.15187L17.2681 14.1394C17.5743 14.6571 17.5809 15.2944 17.2873 15.8147C16.9934 16.3358 16.4439 16.6594 15.8387 16.6653L4.15543 16.6653C3.56326 16.6604 3.01267 16.3358 2.71868 15.8143C2.42501 15.2933 2.43187 14.6546 2.73505 14.1448ZM10.0036 14.9986C10.464 14.9986 10.8372 14.6255 10.8372 14.1653C10.8372 13.7051 10.464 13.332 10.0036 13.332C9.54321 13.332 9.16999 13.7051 9.16999 14.1653C9.16999 14.6255 9.54321 14.9986 10.0036 14.9986ZM10.8397 6.66531H9.17245V12.4986H10.8397V6.66531Z" fill="#FF9E14" />
                              </g>
                            </svg>
                            <span style={{ marginLeft: 5, display: 'flex', alignItems: 'center', fontWeight: 'bold', color: '#FF9E14', fontSize: 15 }}>Pendiente</span>
                          </span> : app?.actualStatus == 'patient-upcoming' ? <span style={{ fontWeight: 'bold', color: app?.actualStatus == 'patient-upcoming' || app?.actualStatus == 'patient-reschedule' ? '#2BAB60' : (app?.actualStatus == 'patient-pending' ? '#FF9E14' : '#3F63CF') }}>
                            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89558 8.65649L14.4141 0.138L15.7104 1.43436L5.89558 11.2492L0.664062 6.0177L1.96042 4.72133L5.89558 8.65649Z" fill="#2BAB60" />
                            </svg>
                            <span style={{ marginLeft: 5, color: app?.actualStatus == 'patient-upcoming' || app?.actualStatus == 'patient-reschedule' ? '#2BAB60' : (app?.actualStatus == 'patient-pending' ? '#FF9E14' : '#3F63CF') }}>Confirmada</span>
                          </span> :
                            app?.actualStatus == 'patient-reschedule' ? <>
                              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89558 8.65649L14.4141 0.138L15.7104 1.43436L5.89558 11.2492L0.664062 6.0177L1.96042 4.72133L5.89558 8.65649Z" fill="#2BAB60" />
                              </svg>
                              <span style={{ marginLeft: 5, fontWeight: 'bold', color: app?.actualStatus == 'patient-upcoming' || app?.actualStatus == 'patient-reschedule' ? '#2BAB60' : (app?.actualStatus == 'patient-pending' ? '#FF9E14' : '#3F63CF') }}>Confirmada - </span>
                              <span style={{ color: app?.actualStatus == 'patient-upcoming' || app?.actualStatus == 'patient-reschedule' ? '#2BAB60' : (app?.actualStatus == 'patient-pending' ? '#FF9E14' : '#3F63CF') }}>Reagendada</span>
                            </> :
                              app?.actualStatus == 'patient-canceled' ? <span style={{ fontWeight: 550, color: app?.actualStatus == 'patient-canceled' ? 'red' : undefined }}>Sesión cancelada</span> :
                                app?.actualStatus == 'patient-complete' ? <span>Sesión Completada</span> :
                                  app?.incomplete ? <span>Sesión no completada</span> :
                                    <>
                                    </>}</p>

                    </div>

                    <p style={{ width: 150, fontSize: 15 }}>
                      {
                        app?.actualStatus == 'patient-pending' && <span style={{ textDecoration: 'underline', fontSize: 15, fontWeight: 500, cursor: 'pointer' }} onClick={() => {
                          setActiveTab('daily')
                          setDate(new Date(app?.appointmentStart))
                        }}>Ver agenda</span>
                      }
                    </p>
                  </div>
                  {app?.line && ref.current++ == 0 && <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: (new Date().getMinutes()/60)*100 }}>
                      <div style={{ height: 1, width: '95vw', background: 'red' }}></div>
                    </div>
                  </div>}
                </div>)
              }
            </div> : <></>)}
        </div>
      </div>
    </div> : (activeTab == 'daily' ? <div className="scheduler-content-container">
      <div className="scheduler-sidebar">
        {hours.map(hour => <div
          className="sidebar-header"
          style={{}
            // let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? {
            //   height: isPhone() ? "28rem" : "20rem",
            // } : dayAppointemnts?.[0] && dayAppointemnts?.[1] && isPhone() ? { height: "28rem" } : {})
          }
        >
          <h3>{hour}</h3>
        </div>)}
      </div>
      <div className="scheduler-content">
        {hours.map(hour => <div
          onClick={() => setExpandedAppointment(null)}
          className="scheduler-content-section"
          style={{
            flexDirection: 'column',
            // flexDirection: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status || isPhone() ? "column" : "row"),
            // height: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? (isPhone() ? "28rem" : "20rem") : (dayAppointemnts?.[0] && dayAppointemnts?.[1] && isPhone() ? "28rem" : (isPhone() ? "11rem" : "9rem"))),
            // justifyContent: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.[0] && dayAppointemnts?.[1] && dayAppointemnts[0].status == dayAppointemnts[1].status ? "space-evenly" : "space-between"),
            justifyContent: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.length == 1 && dayAppointemnts[0].free ? "flex-end" : "flex-start"),
          }}
        >
          {let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => dayAppointemnts?.filter?.(x => x?.actualStatus != 'patient-canceled')?.sort?.((a, b) => new Date(a?.appointmentStart).toISOString() > new Date(b?.appointmentStart).toISOString() ? 1 : -1)?.map(app => <div onClick={ev => [ev.stopPropagation(), setExpandedAppointment(app?._id)]} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {console.log("y-xappointmissssssaaa", app?.slot, app?.rescheduleRequestedBy == app?.appointee?._id)}
            <div className={app?.actualStatus == 'patient-pending' || app?.rescheduleRequestedBy ? "border-pending" : undefined} style={{ display: 'flex', flexDirection: app?.free ? 'row' : 'column', borderRadius: app?.free ? 25 : 10, justifyContent: app?.free ? undefined : 'center', height: app?.free ? '2.3rem' : '4.5rem', marginTop: app?.free ? '.1rem' : '', width: '100%', color: app?.actualStatus == 'patient-upcoming' ? 'white' : '#6EA38C', background: app?.actualStatus == 'patient-upcoming' ? '#6EA38C' : undefined, borderRadius: app?.free ? 25 : 15, border: app?.actualStatus == 'patient-upcoming' ? undefined : '3px solid #6EA38C' }}>
              <p style={{ fontFamily: 'Satoshi', fontWeight: 'bold', fontSize: app?.free ? 10 : 12, margin: 0, marginLeft: 10, marginRight: app?.free ? 2 : undefined, marginBottom: '2px' }}>{app?.patient?.name}{app?.free ? "," : ""}</p>
              <p style={{ fontFamily: 'Satoshi', fontWeight: 500, fontSize: app?.free ? 10 : 12, margin: 0, marginLeft: app?.free ? undefined : 10, textDecoration: app?.actualStatus == 'patient-canceled' ? 'line-through' : undefined }}>{new Date((app?.rescheduleAppointmentStart ? app?.rescheduleAppointmentStart : app?.appointmentStart) - new Date().getTimezoneOffset() * 60 * 1000).toISOString()?.split?.('T')?.join?.(' ')?.split?.('.')?.[0]?.slice?.(-8)?.slice?.(0, 5)} - {new Date((app?.rescheduleAppointmentEnd ? app?.rescheduleAppointmentEnd : app?.appointmentEnd) - new Date().getTimezoneOffset() * 60 * 1000).toISOString()?.split?.('T')?.join?.(' ')?.split?.('.')?.[0]?.slice?.(-8)?.slice?.(0, 5)}</p>
              {console.log("app?.actualStatus == 'patient-canceled'", app?.actualStatus == 'patient-canceled')}
              {expandedAppointment == app?._id && <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: -25 }}>
                  <AppointmentView
                    user={user}
                    onCancel={() => setCancalationModal(() => async () => {
                      const { _id: id, rescheduleRequestedBy, ...props } = app
                      const obj = {
                        status: "patient-canceled",
                        ...(rescheduleRequestedBy ? {
                          rescheduleRequestedBy: null,
                          rescheduleAppointmentStart: null,
                          rescheduleAppointmentEnd: null,
                        } : {})
                      }
                      setAppointments(appointments.map(x => x._id == id ? obj : x))
                      await authReq(
                        "PATCH",
                        `/appointment/${id}`,
                        obj
                      )
                    })}
                    onCall={(params, free, token) => {
                      authReq(
                        "PATCH",
                        `/appointment/${app?._id}`,
                        { professionalJoin: true }
                      )
                      window.open(`${params?.meeting_link}${params?.meeting_link?.includes?.('meet.') ? '' : `&userName=${user?.name}&redirect=${encodeURIComponent(window.location.href)}`}`)
                    }}
                    onReschedule={async ({ _id: id }, free) => setRescheduling({ id, free, app })}
                    onAccept={async ({ _id: id, rescheduleRequestedBy, rescheduleAppointmentStart, rescheduleAppointmentEnd, ...props }) => {
                      const obj = {
                        status: "patient-upcoming",
                        ...(rescheduleRequestedBy ? {
                          rescheduleRequestedBy: null,
                          appointmentStart: rescheduleAppointmentStart,
                          appointmentEnd: rescheduleAppointmentEnd,
                        } : {})
                      }
                      setAppointments(appointments.map(app => app._id == id ? { ...app, actualStatus: 'patient-upcoming' } : app))
                      await authReq(
                        "PATCH",
                        `/appointment/${id}`,
                        obj
                      )
                      // await bookAppointmentGAPI(appointments.find(x => x._id == id))
                    }}
                    appointment={{ ...app, setRefresh: () => { }, type: app.actualStatus }}
                  />
                </div>
              </div>}
            </div>
          </div>))}

          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', backgroundColor: "rgb(224, 224, 224)", top: 0, left: '49vw', width: '2px', height: '100%' }}>
            </div>
          </div>

          {parseInt(hour) >= new Date().getHours() && new Date(date).toISOString().split('T')[0] == new Date().toISOString().split('T')[0] && dailyRef.current++ == 0 && <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: (new Date().getMinutes()/60)*100 - 40 }}>
              <div style={{ height: 1, width: '99vw', background: 'red' }}></div>
            </div>
          </div>}

        </div>)}
      </div>
    </div> : <div className="weekly-scheduler-content-container" style={{ zoom: 0.7 }}>
      <div className="weekly-scheduler-content-sidebar">
        <div className="scheduler-sidebar">
          {["", ...hours].map(hour => <div
            className="sidebar-header-week"
          >
            <h3>{hour}</h3>
          </div>)}
        </div>
      </div>
      <div className="weekly-scheduler-complete-content">
        <div className="weekly-scheduler-days">
          {daysOfWeek(date).map(date => <div className="weekly-scheduler-days-item">
            <h2 style={new Date().toISOString().split("T")[0] == date ? {
              color: "rgba(0, 98, 61, 1)",
              fontWeight: 400,
              fontSize: 24,
            } : {}} onClick={() => {
              setActiveTab('daily')
              setDate(new Date(date))
            }}>{new Date(date).toString().slice(0, 3)}</h2>
            <p style={new Date().toISOString().split("T")[0] == date ? {
              color: "rgba(0, 98, 61, 1)",
              fontWeight: 400,
              fontSize: 16,
            } : {}} onClick={() => {
              setActiveTab('daily')
              setDate(new Date(date))
            }}>{new Date(date).getDate()}</p>
          </div>)}
        </div>

        <div className="weekly-scheduler-hours" onClick={() => setExpandedAppointment(null)}>
          {hours.map(hour => <div className="weekly-scheduler-line">
            {daysOfWeek(date).map(day => <div className="weekly-schedule-block">
              <div className="weekly-schedule-appointment-block-container" style={{
                _: console.log("meeeeeekkkooooosssssooo0", date, hour, `${formatDateYYMMDD(date).split("/")[0]}/${hour.split(':')[0]}`, getAppointmentByDay(date, hour, appointmentsGroupedByDay)),
                justifyContent: let_([getAppointmentByDay(date, hour, appointmentsGroupedByDay)], dayAppointemnts => {
                  console.log("meeeeeekkkooooosssssooo0", date, hour, `${formatDateYYMMDD(date).split("/")[0]}/${hour.split(':')[0]}`, getAppointmentByDay(date, hour, appointmentsGroupedByDay), dayAppointemnts)
                  return dayAppointemnts?.length == 1 && dayAppointemnts[0]?.free ? "flex-end" : "flex-start"
                }),
              }}>
                {console.log("dayhourapp+a", daysOfWeek(date), new Date(day), getAppointmentByDay(new Date(day), hour, appointmentsGroupedByDay))}
                {(getAppointmentByDay(new Date(day), hour, appointmentsGroupedByDay) ?? []).filter(x => x?.actualStatus != 'patient-canceled').sort((a, b) => a?.appointmentStart > b?.appointmentStart ? 1 : -1).map(app => <div onClick={ev => [ev.stopPropagation(), setExpandedAppointment(app?._id)]} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  {console.log("getappoinmt", app?._id, app?.status, app?.actualStatus)}
                  <div id={app?._id} className={app?.actualStatus == 'patient-pending' || app?.rescheduleRequestedBy ? "border-pending" : undefined} style={{ display: 'flex', flexDirection: app?.free ? 'row' : 'column', borderRadius: app?.free ? 25 : 15, justifyContent: app?.free ? undefined : 'center', height: app?.free ? '2.9rem' : '5.5rem', marginTop: app?.free ? '.5rem' : undefined, width: '100%', color: app?.actualStatus == 'patient-upcoming' ? 'white' : '#6EA38C', background: app?.actualStatus == 'patient-upcoming' ? '#6EA38C' : undefined, borderRadius: '5px', border: app?.actualStatus == 'patient-upcoming' ? undefined : '3px solid #6EA38C' }}>
                    <p style={{ fontFamily: 'Satoshi', fontWeight: 'bold', fontSize: app?.free ? 13 : 15, margin: 0, marginLeft: 10, marginRight: app?.free ? 2 : undefined, marginBottom: '2px', textDecoration: app?.actualStatus == 'patient-canceled' && app?.free ? 'line-through' : undefined }}>{app?.patient?.name}{app?.free ? "," : ""}</p>
                    <p style={{ fontFamily: 'Satoshi', fontWeight: 500, fontSize: app?.free ? 13 : 15, margin: 0, marginLeft: app?.free ? undefined : 10, textDecoration: app?.actualStatus == 'patient-canceled' ? 'line-through' : undefined }}>
                      {new Date((app?.rescheduleAppointmentStart ?? app?.appointmentStart) - new Date().getTimezoneOffset() * 60_000).toISOString().split?.('T')?.join?.(' ')?.split?.('.')?.[0]?.slice?.(-8)?.slice?.(0, 5)} - {new Date((app?.rescheduleAppointmentEnd ?? app?.appointmentEnd) - new Date().getTimezoneOffset() * 60_000).toISOString().split?.('T')?.join?.(' ')?.split?.('.')?.[0]?.slice?.(-8)?.slice?.(0, 5)}
                    </p>
                    {console.log("app?.actualStatus == 'patient-canceled'", app?.actualStatus == 'patient-canceled')}
                    {expandedAppointment == app?._id && <div style={{ position: 'relative' }}>
                      <div style={{ position: 'absolute', top: -25 }}>
                        <AppointmentView
                          user={user}
                          givenId="innerid"
                          onCancel={() => setCancalationModal(() => async () => {
                            const { _id: id, rescheduleRequestedBy, ...props } = app
                            const obj = {
                              status: "patient-canceled",
                              ...(rescheduleRequestedBy ? {
                                rescheduleRequestedBy: null,
                                rescheduleAppointmentStart: null,
                                rescheduleAppointmentEnd: null,
                              } : {})
                            }
                            setAppointments(appointments.map(x => x._id == id ? obj : x))
                            await authReq(
                              "PATCH",
                              `/appointment/${id}`,
                              obj
                            )
                          })}
                          onCall={(params, free, token) => {
                            authReq(
                              "PATCH",
                              `/appointment/${app?._id}`,
                              { professionalJoin: true }
                            )
                            window.open(`${params?.meeting_link}${params?.meeting_link?.includes?.('meet.') ? '' : `&userName=${user?.name}&redirect=${encodeURIComponent(window.location.href)}`}`)
                          }}                          onReschedule={async ({ _id: id }, free) => setRescheduling({ id, free, app })}
                          onAccept={async ({ _id: id, rescheduleRequestedBy, rescheduleAppointmentStart, rescheduleAppointmentEnd, ...props }) => {
                            const obj = {
                              status: "patient-upcoming",
                              ...(rescheduleRequestedBy ? {
                                rescheduleRequestedBy: null,
                                appointmentStart: rescheduleAppointmentStart,
                                appointmentEnd: rescheduleAppointmentEnd,
                              } : {})
                            }
                            await authReq(
                              "PATCH",
                              `/appointment/${id}`,
                              obj
                            )
                            // await bookAppointmentGAPI(appointments.find(x => x._id == id))
                          }}
                          appointment={{ ...app, setRefresh: () => { }, type: app.actualStatus }}
                        />
                      </div>
                    </div>}
                  </div>
                </div>)}
              </div>
              {console.log("daysOfWeek(date)", daysOfWeek(date))}
              {new Date().toISOString().split('T')[0] == day && parseInt(hour) >= new Date().getHours() && weeklyRef.current++ == 0 && <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: (new Date().getMinutes()/60)*100 - 60, marginLeft: '-21vw' }}>
                  <div style={{ height: 1, width: '21vw', background: 'red' }}></div>
                </div>
              </div>}
            </div>)}
          </div>)}
        </div>




      </div>
    </div>)}
  </div>
}

const findCalendar = async () => {
  console.log("Called Find CALENDAR")
  const calendars = await gapi.client.calendar.calendarList.list()
  const foundCalendar = calendars.result.items.find(x => x.summary === 'Kavan Health')
  console.log("CALENDAR ITEMS", foundCalendar, calendars.result.items)
  if (!foundCalendar) return null
  return foundCalendar
}

export const initCalendar = async () => {
  const foundCalendar = await findCalendar()
  if (foundCalendar) return foundCalendar.id
  const newCalendar = {
    summary: 'Kavan Health',
    description: 'Your calendar managed by Kavan Health',
    timeZone,
    backgroundColor: '#006039',

  }
  const calendar = await gapi.client.calendar.calendars.insert({
    resource: newCalendar
  })
  console.log("CREATED CALENDAR", calendar.result)
  return calendar.result.id
}

const getAuth = async () => {
  const currentToken = gapi?.client?.getToken()
  if (currentToken) {
    localStorage.setItem("google_token", currentToken.access_token)
    return currentToken.access_token
  }
  const token = localStorage.getItem("google_token")
  console.log(currentToken, "WHAT TOKEN?", token)
  if (!token) return null
  gapi?.client?.setToken({ access_token: token })
  try {
    await initCalendar()
    console.log("OK???????")
  } catch (e) {
    apiCalendar.handleSignoutClick()
    localStorage.removeItem("google_token")
    console.log('recallllllllll????')
    return getAuth()
  }
  return token
}

export const doGAPIAction = async (f) => {
  console.log(await getAuth())
  if (!(await getAuth())) apiCalendar.handleAuthClick()
  const interval = setInterval(async () => {
    const authStatus = !!(await getAuth())
    if (gapi && authStatus) {
      console.log("real status", await getAuth())
      if (authStatus) {
        clearInterval(interval)
        await f(gapi)
      }
    }
  }, 1000)
}

export const bookAppointmentGAPI = givenAppointment => doGAPIAction(async () => {
  const calendarId = await initCalendar()
  console.log("INSERTING Appointment", givenAppointment)
  const insertedEvent = await gapi.client.calendar.events.insert({
    calendarId,
    resource: {
      summary: givenAppointment.patient?.name ? `Appointment with ${givenAppointment.patient?.name}` : "Appointment",
      start: {
        dateTime: new Date(givenAppointment.startDateUTC ?? givenAppointment.appointmentStart).toISOString(),
        timeZone,
      },
      end: {
        dateTime: new Date(givenAppointment.endDateUTC ?? givenAppointment.appointmentEnd).toISOString(),
        timeZone,
      }
    },
    conferenceDataVersion: 1,
  })
  console.log("Inserted event", insertedEvent.result.id)
  await authReq(
    "PATCH",
    `/appointment/${givenAppointment._id ?? givenAppointment.id}`,
    { eventId: insertedEvent.result.id }
  )
})

let currentTip = null;
export const TooltipContent = ({ appointmentData, isPopup, setCurrentTooltip, setAppointmentStatusChanging, appointmentStatusChanging, schedulerData, setSchedulerData, setTooltip, className, style, small, borderColor }) => {
  console.log("smallsmall", small)
  const navigate = useNavigate()
  const [givenAppointment, setGivenAppointment] = useState(appointmentData);
  const [currentDate, setCurrentDate] = useState(Date.now());
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(Date.now())
    }, 60_000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    currentTip = givenAppointment;
  }, [givenAppointment]);

  const modifyAppointments = givenAppointment => setSchedulerData(schedulerData.map(app => app.id == givenAppointment.id ? givenAppointment : app))

  return hidden ? (
    <></>
  ) : (
    <div style={{ ...style, ...(isPopup ? { height: "unset" } : {}) }} className={className} onClick={ev => ev.stopPropagation()}>
      <div className="kwn-appointment_content-container" style={small ? { marginBottom: "1rem" } : { borderLeft: `5px solid ${borderColor}` }}>
        <div className="kwn-appointment-content-slider" style={small || isPhone() ? { display: "none" } : {}}></div>
        <div className="kwn-appointment-content" style={small ? {
          width: "22rem",
        } : {}}>
          {console.log("small5ssmall", small)}
          <div className="kwn-appointment-content-time-container">
            <div className="kwn-appointment-content-time-sub-container">
              <img src={clock} />
              {small ? <h4 style={!small ? { marginLeft: "0.5rem" } : {}}>{new Date(givenAppointment.startDate).toLocaleDateString()} | {new Date(givenAppointment.startDate).toLocaleTimeString().split(":").slice(0, 2).join(":")}</h4> : <h4>
                {dayjs(givenAppointment.startDate).format("ddd MMMM D, YYYY")}
                <span>
                  {" | "}
                  {dayjs(givenAppointment.startDate).format("hh:mm A")}
                </span>
              </h4>}
            </div>
            <div className="kwn-appointment-content-three-dot"></div>
          </div>
          <div className="kwn-appointment-content-divider"></div>
          <div className="kwn-appointment-content-btn-main-container">
            <div className="kwn-appointment-content-profile_name">
              <img src={givenAppointment?.patient?.image} />
              <div className="kwn-appointment-content-profile_name-sub-container">
                <h2>{[givenAppointment?.patient?.name, null][0]}</h2>
                {givenAppointment?.pack && <h2 style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>{[`Pack ${givenAppointment?.totalSessions - givenAppointment?.numberOfSessions}/${givenAppointment?.totalSessions}`, null][0]}</h2>}
                <h3>
                  {givenAppointment?.patient?.preferences?.speciality ?? ""}
                </h3>
              </div>
            </div>

            <div
              className="dashboard-btn-main-container"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: 'wrap',
                width: '20rem'
              }}
            >
              {console.log("small2small", small)}
              <SmallBtn
                small={small}
                onClick={async ev => {

                  if (givenAppointment.status == undefined && (givenAppointment.startDateUTC - 5 * 60 * 1000 > currentDate || givenAppointment.endDateUTC + 5 * 60 * 1000 < currentDate)) return
                  if (givenAppointment.endDateUTC < currentDate && givenAppointment.status == "join") return

                  ev.stopPropagation()
                  if (givenAppointment.status == "join") {
                    setTooltip(null)
                    setAppointmentStatusChanging(true)
                    const givenAppointmentModified = {
                      ...givenAppointment,
                      status: undefined,
                    }
                    setGivenAppointment(givenAppointmentModified);
                    modifyAppointments(givenAppointmentModified);
                    await bookAppointmentGAPI(givenAppointmentModified)
                    await authReq(
                      "PATCH",
                      `/appointment/${givenAppointment.id}`,
                      { status: "patient-upcoming" }
                    );
                    setAppointmentStatusChanging(false)
                  } else if (!(givenAppointment.startDateUTC - 5 * 60 * 1000 > currentDate || givenAppointment.endDateUTC + 5 * 60 * 1000 < currentDate)) window.open(`${givenAppointment?.meeting_link}&userName=Professional`, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes')
                }}
                containerStyle={{
                  marginBottom: small ? "0.5rem" : undefined,
                  // marginTop: givenAppointment.status == "join" && !isPhone() ? '0.5rem' : undefined,
                  display:
                    givenAppointment.status == "none" ? "none" : undefined,
                  backgroundColor:
                    givenAppointment.status == "join"
                      ? "#f8b84e"
                      : "#006039",
                  ...(givenAppointment.status == undefined && (givenAppointment.startDateUTC - 5 * 60 * 1000 > currentDate || givenAppointment.endDateUTC + 5 * 60 * 1000 < currentDate) ? {
                    opacity: 0.5,
                    cursor: "default",
                  } : {}),
                  ...(givenAppointment.endDateUTC - 5 * 60 * 1000 < currentDate && givenAppointment.status == "join" ? {
                    opacity: 0.5,
                    cursor: "default",
                  } : {})
                }}
                fontStyle={{
                  color:
                    givenAppointment.status == "join" ? "#006039" : "#fff",
                }}
                text={
                  givenAppointment.status == "join"
                    ? "Accept"
                    : "Join session"
                }
              />
              {console.log("smallsmall", small)}
              {givenAppointment?.status === "join" && <SmallBtn
                small={small}
                onClick={async () => {
                  if (givenAppointment?.status !== "join") { }
                  else
                    navigate(
                      `/dashboard/AppointmentStack/rescheduleAppointment?id=${givenAppointment.id
                      }${givenAppointment.free || givenAppointment.slot?.includes(":45") ? "&free=1" : ""}`
                    );
                }}
                containerStyle={{
                  border: "0.7px solid #006039",
                  backgroundColor: "#fff",
                  marginBottom: small ? "0.5rem" : undefined,
                }}
                fontStyle={{
                  color: "#006039",
                }}
                text={"Reschedule"}
              />}
              <div className="green-btn">
                <SmallBtn
                  small={small}
                  onClick={async ev => {
                    if (givenAppointment.endDateUTC < currentDate && givenAppointment.status == "join") return
                    if (givenAppointment?.status == "join") {
                      ev.stopPropagation()
                      setAppointmentStatusChanging(true)
                      const givenAppointmentModified = {
                        ...givenAppointment,
                        status: undefined,
                        actualStatus: "patient-canceled",
                      }
                      setGivenAppointment(givenAppointmentModified);
                      modifyAppointments(givenAppointmentModified);
                      setTooltip(null)
                      setCurrentTooltip(null)
                      await authReq(
                        "PATCH",
                        `/appointment/${givenAppointment.id}`,
                        { status: "patient-canceled" }
                      );
                      setAppointmentStatusChanging(false)
                    } else {
                      console.log("APPMENT", givenAppointment)
                      navigate(
                        `/dashboard/AppointmentStack/rescheduleAppointment?id=${givenAppointment.id
                        }${givenAppointment.free || givenAppointment.slot?.includes(":45") ? "&free=1" : ""}`
                      );
                    }
                  }}
                  containerStyle={{
                    marginTop: small ? undefined : "0.5rem",
                    display:
                      givenAppointment.status == "none" ? "none" : undefined,
                    border:
                      givenAppointment.status == "join"
                        ? "none"
                        : "0.7px solid #006039",
                    ...(givenAppointment.endDateUTC - 5 * 60 * 1000 < currentDate && givenAppointment.status == "join" ? {
                      opacity: 0.5,
                      cursor: "default",
                    } : {}),
                    backgroundColor:
                      givenAppointment.status == "join" ? "#EC4B4A" : "#fff",
                  }}
                  fontStyle={{
                    color:
                      givenAppointment.status == "join" ? "#fff" : "#006039",
                  }}
                  text={
                    givenAppointment.status == "join" ? "Cancel" : "Reschedule"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const modifyAppointmentsForUI = app => {

  if (app.startDateUTC) return app

  const startDate = new Date(app.appointmentStart).toISOString();
  const endDate = new Date(app.appointmentEnd).toISOString();
  console.log("stdt1", startDate)
  return {
    ...app,
    color: "#ff9747",
    id: app._id,
    patient: app.appointer,
    startDateUTC: app.appointmentStart,
    endDateUTC: app.appointmentEnd,
    startDate: startDate,
    endDate: endDate,
    actualStatus: app.status,
    title: `Appointment date`,
    free: app.slot?.includes(":45") || new Date(app?.appointmentStart).toISOString().includes(':45'),
    token: app.token,
    numberOfSessions: app.numberOfSessions,
    pack: app.pack,
    totalSessions: app.totalSessions,
    status:
      app.status == "patient-pending"
        ? "join"
        : app.status == "patient-completed"
          ? "none"
          : undefined,
  };
}

const socket = socketIO(baseURL);

const uniqueByApointmentStart = objs => {
  const nobj = {}
  for(const obj of objs) nobj[obj?.appointmentStart] = {
    ...obj,
    appointer: {
      ...obj?.appointer,
      name: obj?.appointer?.name?.split?.('"')?.join?.('')
    }
  }
  return Object.values(nobj)
}

const Appointment = (props) => {
  const navigate = useNavigate();
  const { time } = getAllParams();
  const [expandedAppointment, setExpandedAppointment] = useState(null)
  const [user, setUser] = useState({});
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [schedulerData, setSchedulerData] = useState([]);
  const [date, setDate] = useState(new Date())
  const [rescheduling, setRescheduling] = useState(null)
  const [cancalationModal, setCancalationModal] = useState(null)
  const [rescheduleModal, setRescheduleModal] = useState(null)
  const [userLoading, setUserLoading] = useState(false)
  const rescheduleFunction = useRef(null)

  const setData = (data) =>
    setSchedulerData(
      data.appointments
        // .filter((x) => x.status !== "patient-canceled")
        // .filter((x) => x.status !== "patient-completed")
        .map(modifyAppointmentsForUI)
    );

  useEffect(() => {
    // if(schedulerData?.length) setIsLoading(false)
  }, [schedulerData])

  const doRefresh = () => {
    console.log('sksmksmskmskmsksmks', 124)

    const actionAfterUser = async (data) => {
      console.log("here??", data)
      const user = data.data
      setUser(data.data);
      const stDate = parseInt(new Date(user?.startDateUTC ?? new Date().setHours(0))?.toString?.().split?.(" ")?.[4]?.split?.(":")?.[0]);
      const enDate = parseInt(new Date(user?.endDateUTC ?? new Date().setHours(23)).toString?.().split(" ")[4].split(":")[0]);

      setStartDate(stDate);
      setEndDate(enDate);

      console.log(
        "DATESSS",
        stDate,
        enDate,
        new Date(user?.startDateUTC),
        new Date(user?.endDateUTC)
      )

      console.log("Hello hi!")
      socket.emit("user-enter", { userId: user._id })

      // cache.get(user._id).then(appointments => {
      //   console.log("cache-res", appointments)
      //   if (!schedulerData?.length && appointments) {
      //     // setSchedulerData(appointments)
      //     if (appointments?.length && user?.email) setIsLoading(false)
      //   }
      // })

      // const appointmentData = await authReq("GET", `/appointment?currentDate=${Date.now()}&true&noMessages=true`, null)
      const q = query(collection(fireDB, "appointments"), where("appointee._id", "==", user?._id));
      const querySnapshot = await getDocs(q)
      const appointments = uniqueByApointmentStart(querySnapshot.docs.map(doc => doc.data()))
      // .map(x => ({ ...x, status: 'patient-upcoming' }));
      // querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("4242424224", appointments)
      // console.log(doc.id, " => ", doc.data());
      setData({ appointments })

      const unsub = onSnapshot(q, (querySnapshot) => {
        const appointments = uniqueByApointmentStart(querySnapshot.docs.map(doc => doc.data()))
        // .map(x => ({ ...x, status: 'patient-canceled' }));
        setData({ appointments })
      });

      if (user?.email) setIsLoading(false)
      // })
      // cache.set(user._id, appointmentData.appointments).then(_ => _)

      return () => unsub()
    }

    console.log("localStorage.getItem('user')", localStorage.getItem('user'))
    if (localStorage.getItem('user')) actionAfterUser(JSON.parse(localStorage.getItem('user')))
    else authReq("GET", "/user/me").then(async (data) => {
      localStorage.setItem('user', JSON.stringify(data))
      await actionAfterUser(data)
    })
  };

  const getWeekStart = () => {
    const date = dayjs(new Date());
    // Get the start and end dates of the week that contains the date
    return date.startOf("week");
  };

  useEffect(() => {
    console.log("sksmksmskmskmsksmks")
    props.setActive("calendar")
    doRefresh();

    window.onpopstate = (event) => {
      console.log(
        `location: ${document.location}, state: ${JSON.stringify(event.state)}`
      );
    };


    socket.on("error", err => console.error(err))
    return () => {
      console.log("OK, CALLED", user._id)
      socket.emit("user-leave", { userId: user._id })
      socket.off("unsub-appointments")
      socket.emit("unsub-appointments", { userId: user._id })
    }
  }, []);

  // useEffect(() => {
  //   const f = async () => {
  //     const appointmentData = await authReq("GET", `/appointment?currentDate=${date.getTime()}&noMessages=true`, null)
  //     setData(appointmentData)
  //   }
  //   f()
  // }, [date])

  useEffect(() => {
    socket.on("calendar-modification", async modification => {
      if (!user || !schedulerData?.length) return

      console.log("Modification", modification, schedulerData)
      doGAPIAction(async () => {
        if (modification.actionType == "modify") {
          const calendarId = await initCalendar()
          await gapi.client.calendar.events.update({
            calendarId,
            eventId: modification.eventId,
            resource: {
              summary: modification.appointment.patient?.name ? `Appointment with ${modification.appointment.patient?.name}` : "Appointment",
              start: {
                dateTime: new Date(modification.appointment.appointmentStart - new Date().getTimezoneOffset() * 60_000).toISOString(),
                timeZone: "UTC",
              },
              end: {
                dateTime: new Date(modification.appointment.appointmentEnd - new Date().getTimezoneOffset() * 60_000).toISOString(),
                timeZone: "UTC",
              }
            },
          })
        } else if (modification.actionType == "delete") {
          const calendarId = await initCalendar()
          await gapi.client.calendar.events.delete({
            calendarId,
            eventId: modification.eventId,
          })
        }
      })
      socket.emit("acknowledge", modification)
      setTimeout(() => {
        if (modification.actionType == "modify") setSchedulerData(schedulerData.map(app => app.id == modification.appointment._id ? modifyAppointmentsForUI(modification.appointment) : app))
        else if (modification.actionType == "delete") setSchedulerData(schedulerData.filter(app => app.id != modification.appointment._id))
        else if (modification.actionType == "create") setSchedulerData([...schedulerData, modifyAppointmentsForUI(modification.appointment)])
      }, 3000)
    })
  }, [user, schedulerData])

  return <>
    <div id="appointment-container" className="kavan_admin_main_container" style={rescheduling ? { padding: 0 } : {}}>
      {
        rescheduling && <div onClick={(ev) => {
          ev.stopPropagation()
          setRescheduling(null)
        }} style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100vw', height: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <RescheduleAppointment rescheduleFunction={rescheduleFunction} setRescheduleModal={setRescheduleModal} clearForm={() => {
              setRescheduling(null)
              setExpandedAppointment(null)
            }} id={rescheduling?.[0] ?? rescheduling?._id} appointments={schedulerData} free={rescheduling?.free} app={rescheduling} />
          </div>
        </div>
      }
      {
        rescheduleModal && <div onClick={(ev) => {
          ev.stopPropagation()
          setRescheduling(null)
        }} style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100vw', height: '100vh' }}>
          <div className="satoshi" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '25vw', height: '15vh', padding: '1vw', boxShadow: "4px 4px 15px rgba(57, 61, 72, 0.40)", borderRadius: 25, background: 'white' }}>
              <p style={{ color: '#307B5A', fontWeight: 500, textAlign: 'center', fontSize: 18 }}>¿Esta seguro que quiere cambiar la cita?</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <div style={{ display: 'flex', color: 'white', width: '100%', justifyContent: 'center', marginTop: 10 }}>
                  <div onClick={async () => {
                    console.log("resmodenc", rescheduleModal)
                    setRescheduleModal(null)
                    rescheduleModal()()
                    rescheduleFunction.current()
                  }} style={{ cursor: 'pointer', fontSize: 18, marginRight: '25px', paddingRight: '30px', paddingLeft: '30px', paddingTop: '5px', paddingBottom: '5px', background: '#F4B63B', borderRadius: 25 }}>Si</div>
                  <div onClick={() => setRescheduleModal(null)} style={{ cursor: 'pointer', fontSize: 18, color: 'white', marginRight: '25px', paddingRight: '30px', paddingLeft: '30px', paddingTop: '5px', paddingBottom: '5px', background: '#FE5151', borderRadius: 25 }}>No</div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      }
      {
        cancalationModal && <div onClick={(ev) => {
          ev.stopPropagation()
          setCancalationModal(null)
        }} style={{ position: 'absolute', display: 'flex', justifyContent: 'center', width: '100vw', height: '100vh' }}>
          <div className="satoshi" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ zIndex: 10000, width: '25vw', height: '15vh', padding: '1vw', boxShadow: "4px 4px 15px rgba(57, 61, 72, 0.40)", borderRadius: 25, background: 'white' }}>
              <p style={{ color: '#307B5A', fontWeight: 500, textAlign: 'center', fontSize: 18 }}>¿Esta seguro que quiere cancelar la cita?</p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div></div>
                <div style={{ display: 'flex', color: 'white', width: '100%', justifyContent: 'center', marginTop: 10 }}>
                  <div onClick={() => {
                    setCancalationModal(null)
                    cancalationModal()
                  }} style={{ cursor: 'pointer', fontSize: 18, marginRight: '25px', paddingRight: '30px', paddingLeft: '30px', paddingTop: '5px', paddingBottom: '5px', background: '#F4B63B', borderRadius: 25 }}>Si</div>
                  <div onClick={() => setCancalationModal(null)} style={{ cursor: 'pointer', fontSize: 18, color: 'white', marginRight: '25px', paddingRight: '30px', paddingLeft: '30px', paddingTop: '5px', paddingBottom: '5px', background: '#FE5151', borderRadius: 25 }}>No</div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      }
      {(isLoading || userLoading || !user) && <Loader />}
      {(
        <div className="kwn-appointsments-appointment_view">
          {/* <GoogleCalender signIn={async () => {
            console.log("calendar appointments", schedulerData)
            const calendarId = await clearCalendar()
            for(const appointment of schedulerData) {
              const event = await gapi.client.calendar.events.insert({
                calendarId,
                resource: {
                  summary: appointment.patient?.name ? `Appointment with ${appointment.patient?.name}` : "Appointment",
                  start: {
                    dateTime: new Date(appointment.startDate).toISOString(),
                    timeZone,
                  },
                  end: {
                    dateTime: new Date(appointment.endDate).toISOString(),
                    timeZone,
                  }
                },
                conferenceDataVersion: 1
              })
              console.log("CALENDAR-EVENT", event)
            }
          }} signOut={() => console.log("Signed Out")}/> */}
          {/* {appointmentArray
            .filter((item) => item.type === selectedType.title)
            .map((item) => {
              return item.type === "Upcoming" ? ( */}
          <div style={{ marginTop: '4vh' }}></div>
          {console.log(startDate, endDate)}
          <Scheduler
            date={date}
            setDate={setDate}
            user={user}
            setUserLoading={setUserLoading}
            cancalationModal={cancalationModal}
            setCancalationModal={setCancalationModal}
            rescheduleModal={rescheduleModal}
            setRescheduleModal={setRescheduleModal}
            rescheduling={rescheduling}
            setRescheduling={setRescheduling}
            expandedAppointment={expandedAppointment}
            setExpandedAppointment={setExpandedAppointment}
            schedulerData={schedulerData}
            appointmentStatusChanging={appointmentStatusChanging}
            setAppointmentStatusChanging={setAppointmentStatusChanging}
            setSchedulerData={setSchedulerData}
            TooltipContent={TooltipContent}
            startingHour={Math.max(startDate - 1 ?? 1, 1)}
            endingHour={Math.min(endDate + 1 ?? 24, 24)}
          />
        </div>
      )}
    </div>
  </>;
};

export default Appointment;
