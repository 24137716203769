import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Navbar } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import { chatbotLogo, chatbot1, chatbot2, chatbot3, chatbot4, chatbotSingleChceked, chatbotSingleUnChceked, chatbotMultiUncheckbox, chatbotMultiCheckbox } from "../../../assets";
import { req } from "../../../requests";

import "./chatbot.css"
import { ScaleLoader } from "react-spinners";
import { useMetaPixel } from "../../../metaPixel";

const calculatePercentage = (nameDone, n, questions) => {
    const doneQuestions = n - questions.filter((q, i) => !q.webQuestion && i < n).length
    const totalQuestions = questions.filter(q => q.webQuestion).length
    console.log(nameDone, doneQuestions, totalQuestions, questions.filter((q, i) => q.webQuestion && i < n-1))
    return nameDone ? Math.min(99, ((doneQuestions - 2)/totalQuestions)*100 + 7) : 0
}

const ProgressBar = props => {
  const {progress} = props;

  return <div className="progress-section-container">
    <div className="chatbot-progress-text">
        <span style={{ fontWeight: 700 }}>{Math.round(progress)}% </span>
        <span>complete</span>
    </div>
    <div className="progress-chatbot-container">
      <div className="progress-chat-main" style={{ width: `${progress}%` }}>
      </div>
    </div>
  </div>;
}

const nextIndex = (currIdx, questions) => questions.findIndex((q, i) => q?.webQuestion && i > currIdx)

const isValidName = name => {
    if(name.length == 0) return false
    if(/\d/.test(name)) return false
    return true
}

const ChooseProfile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [ogChat, setOgChat] = useState(null)
  const [name, setName] = useState("")
  const [nameDone, setNameDone] = useState(false)
  const [questions, setQuestions] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const pixel = useMetaPixel()

  useEffect(() => {
    console.log("SENDING REQUEST")
    req('GET', "/chatbot")
        .then(({ chat }) => {
            setOgChat(chat)
            setQuestions(chat?.questions?.map(q => ({ ...q, selectedAnswers: q?.webQuestion ? [] : [q?.answers?.[0]] })))
            setCurrentIndex(nextIndex(0, chat?.questions))
        })
  }, [])

  useEffect(() => {
    document.getElementById(`chatbot-question-${currentIndex}`)?.scrollIntoView({ behavior: "smooth" })
  }, [currentIndex])

  return (
    <div className="chatbot-container">
        <div className="chatbot-start-main" id="chatbot-start-main-id">
            <div className="chatbot-heading">
                <div>
                    <img src={chatbotLogo}/>
                    <h1>KAVAN</h1>
                </div>
                <div>
                    {!nameDone && <p>Skip</p>}
                </div>
            </div>
            <ProgressBar progress={calculatePercentage(nameDone, currentIndex + 1, questions.filter(x => x.webQuestion))} />
            {nameDone ? <div className="chatbot-main-question-container">
                {questions.map(({ question, webQuestion, answers, selectedAnswers, multiselect }, i) => !webQuestion || currentIndex != i ? <div id={`chatbot-question-${i}`} style={{ height: "0px" }}></div> : <div id={`chatbot-question-${i}`} className="chatbot-main-question">
                    <div className="chatbot-main-question-q">{webQuestion ?? question}</div>
                    <div className="chatbot-main-question-answers" >
                        {answers.map(answer => <div className="chatbot-main-question-answer">
                            <img
                                src={selectedAnswers.find(text => text == answer) ? (multiselect ? chatbotMultiCheckbox : chatbotSingleChceked) : (multiselect ? chatbotMultiUncheckbox : chatbotSingleUnChceked)}
                                onClick={() => {
                                    selectedAnswers.find(text => text == answer) ?
                                        setQuestions(questions.map((q, j) => j == i ? {...q, selectedAnswers: (selectedAnswers ?? []).filter(ans => ans != answer)} : q)) :
                                        setQuestions(questions.map((q, j) => j == i ? {...q, selectedAnswers: multiselect ? [...selectedAnswers, answer] : [answer]} : q)) 
                                    } 
                                }
                            />
                            <p id={answer.replace("(Let the user write the answer)", "").split(" ").join("-").toLowerCase()}>{answer.replace("(Let the user write the answer)", "")}</p>
                        </div>)}
                    </div>
                </div>)}
            </div> : <div className="chatbot-main-question-container" style={{ height: "20vh" }}>
            <div className="chatbot-main-question-q q-starter">
                <h2>Para empezar, ¿cómo te llamas?</h2>
            </div>
                <div className="chatbot-main-question-answers2 chatbot-main-question-answers-starter">
                    <input type="text" placeholder="Ingresa tu nombre aquí" value={name} onChange={ev => setName(ev.target.value)}/>
                </div>
            </div>}
            <div className="chatbot-main-list-container">
                <div className="chatbot-main-list-item">
                    <img src={chatbot4}/>
                    <p style={{ fontSize: "15px", color: "#767387"}}>Nuestra función es filtrar las necesidades del usuario y ponerlo en contacto con los mejores profesionales para que lo atiendan. <br/> Nuestras conversaciones son privadas, las analizamos como herramienta de búsqueda para encontrar al profesional que el usuario necesita.</p>
                </div>
            </div>
            {console.log("currInd3x", currentIndex)}
            <div className="chatbot-main-button" style={{
                    _: console.log(nameDone), 
                    opacity: !nameDone ? (isValidName(name) ? 1 : 0.5) : (questions[currentIndex]?.optional ? 1 : (questions[currentIndex]?.selectedAnswers?.length >= 1 ? 1 : 0.5)),
                    cursor: !nameDone ? (isValidName(name) ? 'pointer' : 'default') : (questions[currentIndex]?.optional ? 'pointer' : (questions[currentIndex]?.selectedAnswers?.length >= 1 ? 'pointer' : 'default'))
            }} onClick={async () => {
                if(!nameDone) {
                    if(isValidName(name)) {
                        pixel.trackCustom('EnteredNicknameWeb')
                        setNameDone(true)
                    }
                    return 
                }
                const isEnabled = questions[currentIndex]?.optional ? true : (questions[currentIndex]?.selectedAnswers?.length >= 1 ? true : false)
                if(!isEnabled) return
                const nextQuestionIndex = nextIndex(currentIndex, questions)
                const nextQuestion = questions[nextQuestionIndex]
                console.log(nextQuestion)
                if(!nextQuestion) {
                    setLoading(true)
                    const newChat = questions.map(q => ({ ...q, answers: q.selectedAnswers, selectedAnswers: [] }))
                    const { doctors } = await req('POST', '/chatbotWeb', { chat: newChat, email: "default@chatbot.com" })
                    setLoading(false)
                    console.log('chatbot',doctors);
                    pixel.trackCustom('CompletedChatbotWeb')
                    navigate('/weblogin', {
                        state: {
                            doctors: doctors?.doctors,
                            reviews: doctors?.reviews,
                        }
                    });
                    return
                }
                setCurrentIndex(nextQuestionIndex)
            }}>
                {loading ? <ScaleLoader color="white" /> : <p>Siguiente</p>}
            </div>
        </div>
    </div>
  );
};

export default ChooseProfile;
