// import React, { useState } from "react";
// import './CustomPhoneIput.css'

// const CustomPhoneInput = () => {
//   const [selectedCode, setSelectedCode] = useState("+1");
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const countryCodes = [
//     { code: "+1", country: "US" },
//     { code: "+91", country: "India" },
//     { code: "+44", country: "UK" },
//     { code: "+61", country: "Australia" },
    
//   ];

//   const handleCodeChange = (e) => {
//     setSelectedCode(e.target.value);
//   };

//   const handlePhoneChange = (e) => {
//     setPhoneNumber(e.target.value);
//   };

//   return (
//     <div style={{display:'flex',flexDirection:'column',}}>
//         <h3>Title of the text field</h3>
//         <div style={{display:'flex',flexDirection:'row',backgroundColor:"green",height:60,width:'100%'}}>
//             <div style={{display:'flex',backgroundColor:'red',flex:0.2,justifyContent:'center',alignItems:'center',}}>
//             <select
//         value={selectedCode}
//         onChange={handleCodeChange}
//         style={{}}
//       >
//         {countryCodes.map((code) => (
//           <option key={code.code} value={code.code}>
//             {code.code} ({code.country})
//           </option>
//         ))}
//       </select>
//             </div>
//             <div style={{display:'flex',backgroundColor:'red',flex:0.2,justifyContent:'center',alignItems:'center',}}>
//             <input
//         type="tel"
//         value={phoneNumber}
//         onChange={handlePhoneChange}
//         placeholder="Enter phone number"
        
//       />
//             </div>
            

//         </div>

//     </div>
//   );
// };

// export default CustomPhoneInput;


import React, { useState } from "react";
import InputButton from "../input_button/Button";
import './CustomPhoneIput.css'


const CustomPhoneInput = ({title,phonenumber,setphonenumber,selectedcode,setselectedcode,codeVisible,placeholder}) => {
  

  const countryCodes = [
    { code: "+1", country: "US" },
    { code: "+91", country: "India" },
    { code: "+44", country: "UK" },
    { code: "+61", country: "Australia" },
    { code: "+33", country: "France" },
    { code: "+49", country: "Germany" },
    { code: "+81", country: "Japan" },
    { code: "+7", country: "Russia" },
    { code: "+55", country: "Brazil" },
    { code: "+20", country: "Egypt" },
    { code: "+34", country: "Spain" },
    { code: "+39", country: "Italy" },
    { code: "+27", country: "South Africa" },
    { code: "+86", country: "China" },
    { code: "+52", country: "Mexico" },
    { code: "+63", country: "Philippines" },
    { code: "+27", country: "South Africa" },
    { code: "+82", country: "South Korea" },
    { code: "+46", country: "Sweden" },
    { code: "+47", country: "Norway" },
    { code: "+48", country: "Poland" },
    { code: "+31", country: "Netherlands" },
    { code: "+32", country: "Belgium" },
    { code: "+41", country: "Switzerland" },
    { code: "+45", country: "Denmark" },
    { code: "+48", country: "Poland" },
    { code: "+34", country: "Spain" },
    { code: "+1", country: "Canada" },
    { code: "+966", country: "Saudi Arabia" },
    { code: "+66", country: "Thailand" },
    { code: "+60", country: "Malaysia" },
    { code: "+64", country: "New Zealand" },
    { code: "+94", country: "Sri Lanka" },
    { code: "+90", country: "Turkey" },
    { code: "+62", country: "Indonesia" },
    { code: "+20", country: "Egypt" },
    { code: "+380", country: "Ukraine" },
    { code: "+370", country: "Lithuania" },
    { code: "+234", country: "Nigeria" },
    { code: "+225", country: "Ivory Coast" },
    { code: "+218", country: "Libya" },
    { code: "+251", country: "Ethiopia" },
    { code: "+963", country: "Syria" },
    { code: "+974", country: "Qatar" },
    { code: "+971", country: "UAE" },
    { code: "+1", country: "Barbados" },
    { code: "+354", country: "Iceland" },
    { code: "+354", country: "Iceland" },
    { code: "+358", country: "Finland" },
    { code: "+45", country: "Denmark" },
    { code: "+64", country: "New Zealand" },
  ];
  

  const handleCodeChange = (e) => {
    setselectedcode(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setphonenumber(e.target.value);
  };

  return (
    <div className="phoneIputMain">
      <h3 style={{fontSize:15,fontFamily: 'Satoshi, sans-serif',color:'#120D30'}}>{title}</h3>
      <div className="phone-input-wrapper">
       {codeVisible&& <select
          value={selectedcode}
          onChange={handleCodeChange}
          className="dropdown"
        >
          {countryCodes.map((code) => (
            <option key={code.code} value={code.code}>
              {code.code} 
            </option>
          ))}
        </select>}
        <input
          type="tel"
          value={phonenumber}
          onChange={handlePhoneChange}
          placeholder={placeholder}
          className="phone-input"
        />
       
      </div>
    </div>
  );
};

export default CustomPhoneInput;
